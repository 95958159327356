import React, { useState, useEffect } from "react";
import { Button, Dialog, Select } from "@material-ui/core";
import { getLeadById, getLodgeById, updateAssignedLodge } from "../utils/utils";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import statuses from "../data/statuses";

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 10,
  },
});

const LeadPopup = ({
  handleClose,
  handleOpen,
  leadId,
  type,
  lodges,
  lodgeId,
}) => {
  const classes = useStyles();
  const [lead, setLead] = useState(null);
  const [lodge, setLodge] = useState(null);
  const [value, setValue] = useState(lodges && lodges[0]);
  useEffect(() => {
    if (type === "admin") {
      handleGetLodgeInfo();
    } else {
      handleGetLead();
    }
  }, [leadId, lodgeId]);

  const handleGetLead = async () => {
    const data = await getLeadById(leadId);
    console.log(data);

    setLead(data);
  };

  const handleGetLodgeInfo = async () => {
    const data = await getLodgeById(lodgeId);
    setLodge(data);
  };
  const handleSubmit = async () => {
    const data = await updateAssignedLodge(leadId, value.lodgeId);
    console.log(data);
  };
  return (
    <div className="lead-popup">
      <Dialog
        className={classes.root}
        open={handleOpen}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle
          disableTypography
          className="flex justify-between items-center p-11 bg-hs-grey"
        >
          <h3>Contact Info</h3>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {type === "admin" ? (
          <>
            <h1 className="text-3xl pb-4">Lodge details</h1>
            <p className="pb-4">Lodge Name: {lodge && lodge.lodgeName}</p>
            <p className="pb-4">City: {lodge && lodge.city}</p>
            <p className="pb-4">District: {lodge && lodge.districtId}</p>

            <Button
              variant="outlined"
              component={Link}
              to={`/lodge/${lodgeId}`}
            >
              View Lodge
            </Button>
          </>
        ) : (
          <>
            <div className="dialog-content" style={{ padding: "30px" }}>
              <p className="pb-4">First Name: {lead && lead.firstName}</p>
              <p className="pb-4">Last Name: {lead && lead.lastName}</p>
              <p className="pb-4">Email: {lead && lead.email}</p>
              <p className="pb-4">Phone: {lead && lead.phone}</p>
              <p className="pb-4">Address 1: {lead && lead.addressOne}</p>
              <p className="pb-4">
                Current Status: {lead && statuses[lead.status]}
              </p>

              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/profile/${leadId}`}
              >
                View Profile
              </Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default LeadPopup;
