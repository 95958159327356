import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { handleAddContactToDb } from "../utils/utils";
import Autocomplete from "@mui/material/Autocomplete";
import { toast, ToastContainer } from "react-toastify";
import statuses from "../data/statuses";

/*const statuses = [
  "ebook",
  "consultation",
  "leftMessage",
  "spokeWith",
  "invited",
  "visited",
  "requestedPetition",
  "petitionSubmitted",
  "investigationAssigned",
  "investigationComplete",
  "ballotComplete",
  "eaInitiation",
  "eaProf",
  "fcPassing",
  "fcProf",
  "mmRaising",
  "mmProf",
  "archived",
]; */

const AddContact = ({ submitting, setSubmitting, lodgeId }) => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zip: "",
    status: "",
  });

  const handleChange = (e) => {
    setContactInfo((previousValues) => ({
      ...previousValues,
      [e.target.name]: e.target.value,
      status: selectedStatus,
    }));
  };

  const handleSubmit = async () => {
    console.log(contactInfo);
    if (selectedStatus === "") {
      toast.error("A status is required...");
      return;
    } else if (contactInfo.firstName === "") {
      toast.error("A first name is required...");
      return;
    } else if (contactInfo.lastName === "") {
      toast.error("A last name is required...");
      return;
    } else if (contactInfo.email === "") {
      toast.error("An email address is required...");
      return;
    }
    setSubmitting(true);

    await handleAddContactToDb(contactInfo, lodgeId);
  };

  return (
    <div>
      <div className="p-11" style={{ minWidth: "500px" }}>
        <h3 className="text-2xl">Please Enter Contact Info</h3>
        <div className="pt-4">
          <div className="p-3">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={Object.values(statuses)}
              sx={{ width: 300 }}
              //getOptionLabel={(option) => option.lodgeName}

              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Current Status"
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setSelectedStatus(
                  Object.keys(statuses).find(
                    (key) => statuses[key] === newValue
                  )
                );
                console.log(newValue);
              }}
            />
          </div>

          <div className="m-2">
            <TextField
              name="firstName"
              label="First Name"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>
          <div className="m-2">
            <TextField
              name="lastName"
              label="Last Name"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>

          <div className="m-2">
            <TextField
              name="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>

          <div className="m-2">
            <TextField
              name="phone"
              label="Phone Number"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>

          <div className="m-2">
            <TextField
              name="addressOne"
              label="Address One"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>

          <div className="m-2">
            <TextField
              name="addressTwo"
              label="Address Two"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>

          <div className="m-2">
            <TextField
              name="city"
              label="City"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>

          <div className="m-2">
            <TextField
              name="state"
              label="State"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>

          <div className="m-2">
            <TextField
              name="zip"
              label="Zip"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </div>

          <div className="m-2">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              disabled={submitting}
            >
              Add Contact
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddContact;
