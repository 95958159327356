import React, { useState, useEffect } from "react";
import { Card, CardContent, TextField, Button } from "@material-ui/core";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { getTextTemplates } from "../utils/utils";
import EmailTemplates from "../pages/EmailTemplates";
import { toast, ToastContainer } from "react-toastify";
import { getLodgeTwilio } from "../utils/utils";

const SendText = ({ leadId, toNum, lodgeId }) => {
  const [message, setMessage] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    handleGetTemplates();
  }, [lodgeId]);

  const handleGetTemplates = async () => {
    const data = await getTextTemplates(lodgeId);
    console.log(data);
    setTemplates(data);
  };

  const handleSendText = async () => {
    setSending(true);
    const twilioNumber = await getLodgeTwilio(lodgeId);
    console.log(twilioNumber);
    const body = {
      toNum: toNum,
      leadId: leadId,
      message:
        "DO NOT REPLY.  THIS NUMBER DOES NOT RECEIVE TEXTS OR CALLS AT THIS TIME. ------ " +
        message,
      lodgeId: lodgeId,
      fromNum: twilioNumber,
    };

    const response = await axios.post(
      "https://us-central1-freemasons-a9cdf.cloudfunctions.net/sendTextToLead",
      body
    );

    console.log(response);
    if (response.status === 200) {
      toast.success("Your text has been sent...");
      setSelectedTemplate(null);
      setMessage("");
    } else {
      toast.error("There was an error sending your text...");
    }

    setSending(false);
  };
  return (
    <div className="send-email">
      <Card>
        <CardContent>
          <h3>Send A Text</h3>
          {lodgeId && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={templates}
              getOptionLabel={(option) => option.templateName}
              renderInput={(params) => (
                <TextField {...params} label="Template" />
              )}
              onChange={(event, newValue) => {
                setSelectedTemplate(newValue);
                setMessage(newValue.message);
                console.log(newValue);
              }}
            />
          )}
          <div className="pb-4" />

          <div className="pt-10" />
          <TextField
            variant="outlined"
            multiline
            rows={6}
            maxRows={8}
            fullWidth
            label="Message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="pt-10" />
          <Button
            disabled={sending}
            variant="contained"
            color="primary"
            onClick={handleSendText}
          >
            Send Text
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default SendText;
