import React, { useState, useEffect } from "react";
import { Card, CardContent, TextField, Button } from "@material-ui/core";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { getEmailTemplates } from "../utils/utils";
import EmailTemplates from "../pages/EmailTemplates";
import { toast, ToastContainer } from "react-toastify";

const SendEmail = ({ leadId, toEmail, lodgeId }) => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [sending, setSending] = useState(false);
  const [cc, setCc] = useState([]);

  useEffect(() => {
    handleGetTemplates();
  }, [lodgeId]);

  const handleGetTemplates = async () => {
    const data = await getEmailTemplates(lodgeId);
    console.log(data);
    setTemplates(data);
  };

  const handleSendEmail = async () => {
    setSending(true);
    const body = {
      subject: subject,
      leadId: leadId,
      content:
        "DO NOT REPLY.  THIS ACCOUNT DOES NOT RECEIVE EMAILS AT THIS TIME. -------- " +
        content,
      toEmail: toEmail,
      cc: cc,
    };

    const response = await axios.post(
      "https://us-central1-freemasons-a9cdf.cloudfunctions.net/sendEmailToLead",
      body
    );

    console.log(response.status);
    if (response.status === 200) {
      toast.success("Your email has been sent...");
      setSelectedTemplate(null);
      setSubject("");
      setContent("");
      setCc([]);
    } else {
      toast.error("There was an error sending your email...");
    }

    setSending(false);
  };

  const handlePrepareCC = (e) => {
    const emails = e.target.value.split(",");
    console.log(emails);
    setCc(emails);
  };
  return (
    <div className="send-email">
      <Card>
        <CardContent>
          <h3>Send an Email</h3>
          {lodgeId && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={templates}
              getOptionLabel={(option) => option.templateName}
              renderInput={(params) => (
                <TextField {...params} label="Template" />
              )}
              onChange={(event, newValue) => {
                setSelectedTemplate(newValue);
                setSubject(newValue.subject);
                setContent(newValue.message);
                console.log(newValue);
              }}
            />
          )}
          <div className="pb-4" />
          <TextField
            label="CC/BCC (Seperated by ',')"
            fullWidth
            value={cc}
            variant="outlined"
            onChange={handlePrepareCC}
          />
          <div className="pb-4" />
          <TextField
            label="Subject"
            fullWidth
            value={subject}
            variant="outlined"
            onChange={(e) => setSubject(e.target.value)}
          />
          <div className="pt-10" />
          <TextField
            variant="outlined"
            multiline
            rows={6}
            maxRows={8}
            fullWidth
            label="Message..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <div className="pt-10" />
          <Button
            disabled={sending}
            variant="contained"
            color="primary"
            onClick={handleSendEmail}
          >
            Send Email
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default SendEmail;
