import React from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import { useHistory } from "react-router-dom";

const Table = (props) => {
  let history = useHistory();
  console.log(props.data);
  const columns = [
    {
      name: "Lodge Name",
      selector: (row) => row["lodgeName"],
      sortable: true,
      grow: 2,
    },

    {
      name: "Address",
      selector: (row) => row["addressOne"],
      sortable: true,
      grow: 2,
    },

    {
      name: "Master",
      selector: (row) => row["masterName"],
      sortable: true,
      grow: 2,
    },

    {
      name: "Master Phone",
      selector: (row) => row["masterPhone"],
      sortable: true,
      grow: 2,
    },

    {
      name: "City",
      selector: (row) => row["city"],
      sortable: true,
      hide: "md",
    },
    {
      name: "View Profile",
      button: true,
      cell: (row) => (
        <>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => history.push(`/lodge/${row["lodgeId"]}`)}
            style={{ marginRight: "5px" }}
          >
            View
          </button>
        </>
      ),
    },
  ];
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = props.data.filter(
    (item) =>
      (item.lodgeName &&
        item.lodgeName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.masterName &&
        item.masterName.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title=""
      columns={columns}
      data={filteredItems}
      pagination
      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      selectableRows
      persistTableHead
    />
  );
};

export default Table;
