import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import {
  getLeadById,
  getLodgeById,
  getLodges,
  getUser,
  updateAssignedLodge,
  updateLeadInfo,
  updateCurrentStatus,
  getLeadActivityHistory,
} from "../utils/utils";
import "../css/Profile.css";
import { Card, CardContent, Grid, TextField, Button } from "@material-ui/core";
import { getNotes, saveNote } from "../utils/utils";
import SendEmail from "../components/SendEmail";
import { DashboardLayout } from "../components/Layout";
import { toast, ToastContainer } from "react-toastify";
import MessageTabs from "../components/MessageTabs";
import SendText from "../components/SendText";
import UserContext from "../contexts/UserContext";
import Autocomplete from "@mui/material/Autocomplete";
import statuses from "../data/statuses";

const Profile = ({ profile }) => {
  const statusArray = Array.from(statuses);
  /* const statuses = [
    "ebook",
    "consultation",
    "leftMessage",
    "spokeWith",
    "invited",
    "visited",
    "requestedPetition",
    "petitionSubmitted",
    "investigationAssigned",
    "investigationComplete",
    "ballotComplete",
    "eaInitiation",
    "eaProf",
    "fcPassing",
    "fcProf",
    "mmRaising",
    "mmProf",
    "archived",
  ]; */
  const { leadId } = useParams();
  const { user } = useContext(UserContext);
  const [lead, setLead] = useState(null);
  const [notes, setNotes] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [updatedLeadInfo, setUpdatedLeadInfo] = useState({});
  const [showSave, setShowSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [lodges, setLodges] = useState("");
  const [selectedLodge, setSelectedLodge] = useState("");
  const [lodgeName, setLodgeName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [stageActivity, setStageActivity] = useState(null);
  useEffect(() => {
    if (!user) return;
    handleGetUser();
    handleGetLeadData();
    handleGetNotes();
    handleGetLodges();
    handleGetLeadHistory();
  }, [leadId]);

  const handleGetLeadHistory = async () => {
    const data = await getLeadActivityHistory(leadId);
    setStageActivity(data);
    console.log(data);
  };

  const handleGetUser = async () => {
    const userInfo = await getUser(user.uid);
    setUserInfo(userInfo);
  };

  const handleGetLodges = async () => {
    const lodges = await getLodges();
    setLodges(lodges);
    console.log(lodges);
  };

  const handleGetLeadData = async () => {
    const data = await getLeadById(leadId);
    handleGetLodgeName(data.assignedLodge);
    setLead(data);
  };

  const handleGetNotes = async () => {
    const data = await getNotes("leadNote", leadId);
    setNotes(data);
  };

  const handleSaveNote = async () => {
    const savedNoteResponse = await saveNote("leadNote", leadId, newNote);

    console.log(savedNoteResponse);

    setNewNote("");
    toast.success("Your Note has been saved...");

    await handleGetNotes();
  };

  const handleChange = (e) => {
    setUpdatedLeadInfo({
      firstName: lead.firstName || "",
      lastName: lead.lastName || "",
      email: lead.email || "",
      phone: lead.phone || "",
      addressOne: lead.addressOne || "",
      addressTwo: lead.addressTwo || "",
      city: lead.city || "",
      state: lead.state || "",
      status: lead.status || "",
      zip: lead.zip || "",
      areYouMale: lead.areYouMale || "",
      age: lead.age || "",
      believeInGod: lead.believeInGod || "",
      felonies: lead.felonies || "",
      howDid: lead.howDid || "",
      aboutYou: lead.aboutYou || "",
      status: selectedStatus !== "" ? selectedStatus : lead.status || "",
    });

    setUpdatedLeadInfo((previousValues) => ({
      ...previousValues,
      [e.target.name]: e.target.value,
    }));

    setShowSave(true);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const saving = await updateLeadInfo(leadId, updatedLeadInfo);
    setIsSaving(false);
    handleGetLeadData();
    toast.success("Your changes have been saved...");
  };

  const displayDateAsString = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleAssignLodge = async () => {
    setIsSaving(true);
    await updateAssignedLodge(lead.leadId, selectedLodge.lodgeId);
    //console.log("lead has been assigned!");
    handleGetLeadData();
    toast.success("The Lodge has been updated...");
    setIsSaving(false);
  };

  const handleUpdateStatus = async () => {
    await updateCurrentStatus(lead.leadId, selectedStatus);

    handleGetLeadData();
  };

  const handleGetLodgeName = async (lodgeId) => {
    const lodgeInfo = await getLodgeById(lodgeId);
    //console.log(lodgeInfo);
    setLodgeName(lodgeInfo.lodgeName);
  };

  //console.log(statusArray);
  return (
    <DashboardLayout>
      <h2 className="text-left text-3xl p-11 bg-hs-grey shadow-md">
        CONTACT PROFILE
      </h2>
      <div className="profile-info p-10">
        <Grid container spacing={5} justify="left">
          <Grid item lg={6} xs={12}>
            <Card style={{ padding: "20px" }}>
              <CardContent>
                <h3 className="text-2xl pb-3">CONTACT INFO</h3>
                {userInfo && userInfo[0].type === "admin" ? (
                  <>
                    <div className="inline">
                      <p className=" inline">Current Assigned Lodge:</p>
                      <p className="text-2xl p-3">{" " + lodgeName}</p>
                    </div>

                    <p>Or you can assign a new lodge...</p>
                    <div className="p-3">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={lodges}
                        sx={{ width: 300 }}
                        getOptionLabel={(option) => option.lodgeName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Lodges"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, newValue) => {
                          setSelectedLodge(newValue);
                          console.log(newValue);
                        }}
                      />
                    </div>
                    <div className="p-3">
                      <Button
                        disabled={isSaving}
                        variant="contained"
                        color="primary"
                        onClick={handleAssignLodge}
                      >
                        Assign Lodge
                      </Button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <p>Current Status: {lead && statuses[lead.status]} </p>
                <div className="p-3">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={Object.values(statuses)}
                    sx={{ width: 300 }}
                    //getOptionLabel={(option) => option.lodgeName}
                    defaultValue={lead && lead.status}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Update Status"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, newValue) => {
                      setSelectedStatus(
                        Object.keys(statuses).find(
                          (key) => statuses[key] === newValue
                        )
                      );
                      console.log(newValue);
                    }}
                  />
                  <div className="p-5">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdateStatus}
                    >
                      Update Status
                    </Button>
                  </div>
                </div>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="firstName"
                      defaultValue={lead.firstName}
                      variant="outlined"
                      label="First Name"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>
                <p className="m-3">
                  {lead && (
                    <TextField
                      name="lastName"
                      defaultValue={lead.lastName}
                      variant="outlined"
                      label="Last Name"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>
                <p className="m-3">
                  {lead && (
                    <TextField
                      name="email"
                      defaultValue={lead.email}
                      variant="outlined"
                      label="Email Address"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>
                <p className="m-3">
                  {lead && (
                    <TextField
                      name="phone"
                      defaultValue={lead.phone}
                      variant="outlined"
                      label="Phone #"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>
                <p className="m-3">
                  {lead && (
                    <TextField
                      name="addressOne"
                      defaultValue={lead.addressOne}
                      variant="outlined"
                      label="Address One"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>
                <p className="m-3">
                  {lead && (
                    <TextField
                      name="addressTwo"
                      defaultValue={lead.addressTwo}
                      variant="outlined"
                      label="Address Two"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>
                <p className="m-3">
                  {lead && (
                    <TextField
                      name="city"
                      defaultValue={lead.city}
                      variant="outlined"
                      label="City"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="state"
                      defaultValue={lead.state}
                      variant="outlined"
                      label="State"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="county"
                      defaultValue={lead.county}
                      variant="outlined"
                      label="County"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="zip"
                      defaultValue={lead.zip}
                      variant="outlined"
                      label="Zip"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="areYouMale"
                      defaultValue={lead.areYouMale}
                      variant="outlined"
                      label="Are you male?"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="age"
                      defaultValue={lead.age}
                      variant="outlined"
                      label="What is your age?"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="believeInGod"
                      defaultValue={lead.believeInGod}
                      variant="outlined"
                      label="Do you believe in a Supreme Being?"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="felonies"
                      defaultValue={lead.felonies}
                      variant="outlined"
                      label="Do you have a felony arrest record?"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="howDid"
                      defaultValue={lead.howDid}
                      variant="outlined"
                      label="How did you learn about us?"
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                <p className="m-3">
                  {lead && (
                    <TextField
                      name="aboutYou"
                      defaultValue={lead.aboutYou}
                      variant="outlined"
                      label="Tell us about yourself and your interest in Freemasonry."
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </p>

                {showSave && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={isSaving}
                    fullWidth
                  >
                    Save Changes
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Card>
              <CardContent>
                <div className="">
                  <h3 className="text-2xl pb-3">ADD NOTE</h3>
                  <TextField
                    label="Notes"
                    fullWidth
                    multiline
                    variant="outlined"
                    rows={4}
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                  />
                </div>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveNote}
                >
                  Save Note
                </Button>
              </CardContent>
            </Card>
            <div className="p-3" />
            <Card style={{ marginRight: "1%" }}>
              <CardContent>
                <h3 className="text-2xl pb-3">NOTE HISTORY</h3>
                <ol>
                  {notes &&
                    notes.map((note) => (
                      <>
                        <li className="p-2" key={note.content}>
                          {note.content} | {displayDateAsString(note.created)}
                        </li>
                      </>
                    ))}
                </ol>
              </CardContent>
            </Card>

            <div className="p-3" />
            <Card style={{ marginRight: "1%" }}>
              <CardContent>
                <h3 className="text-2xl pb-3">STAGE HISTORY</h3>
                <ol>
                  {stageActivity &&
                    stageActivity.map((activity) => (
                      <>
                        <li className="p-2" key={activity.created}>
                          <h3 className="">
                            Move from {statuses[activity.fromLane]} <b>TO</b>
                            {statuses[activity.toLane]}
                          </h3>
                          {activity.notes} |{" "}
                          {displayDateAsString(activity.created)}
                        </li>
                      </>
                    ))}
                </ol>
              </CardContent>
            </Card>
            <div className="pt-10" />
            {userInfo && userInfo[0].type === "setup" ? (
              <MessageTabs
                itemOne={
                  lead && (
                    <SendEmail
                      leadId={leadId}
                      toEmail={lead.email}
                      lodgeId={lead.assignedLodge}
                    />
                  )
                }
                itemTwo={
                  lead && (
                    <SendText
                      leadId={leadId}
                      toNum={lead.phone}
                      lodgeId={lead.assignedLodge}
                    />
                  )
                }
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <ToastContainer />
      </div>
    </DashboardLayout>
  );
};

export default Profile;
