import React, { useState, useContext, useEffect } from "react";
import Forms from "../components/Forms";
import { DashboardLayout } from "../components/Layout";
import UserContext from "../contexts/UserContext";
import { getUser } from "../utils/utils";
import AdminForms from "../components/AdminForms";
const FormsPage = () => {
  const { user } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    if (!user) return;
    handleGetUser();
  }, []);

  const handleGetUser = async () => {
    const userInfo = await getUser(user.uid);
    setUserInfo(userInfo);
    console.log(userInfo);
  };

  return (
    <DashboardLayout>
      {userInfo && userInfo[0].type === "admin" ? (
        <>
          {userInfo && (
            <AdminForms
              lodgeId={userInfo[0].lodgeId}
              formIds={["1234", "14534"]}
            />
          )}
        </>
      ) : (
        <>{userInfo && <Forms lodgeId={userInfo[0].lodgeId} />} </>
      )}
    </DashboardLayout>
  );
};

export default FormsPage;
