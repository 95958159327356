import React from "react";
import { DashboardLayout } from "../components/Layout";

const MassSmsGetStarted = () => {
  return (
    <DashboardLayout>
      <h3 className="p-11 text-3xl">Get Started</h3>
    </DashboardLayout>
  );
};

export default MassSmsGetStarted;
