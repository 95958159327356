import { Button, TextField } from "@material-ui/core";
import Reac, { useState } from "react";
import { DashboardLayout } from "../components/Layout";
import AddContact from "../components/AddContact";
const AddContactPage = () => {
  return (
    <DashboardLayout>
      <h3 className="p-11 text-3xl bg-hs-grey shadow-md flex items-center">
        Add A Contact
      </h3>
      <AddContact />
    </DashboardLayout>
  );
};

export default AddContactPage;
