import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import firebaseConfig from "./config";

class Firebase {
  constructor() {
    //initializing firebase app for frontend changes
    app.initializeApp(firebaseConfig);
    this.app = app;
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  //Signup/register for new account function
  async register(name, email, password) {
    const newUser = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );

    this.db.collection("users").add({
      email: email,
      name: name,
      userId: newUser.user.uid,
      type: "setup",
    });
    return newUser.user.updateProfile({
      displayName: name,
    });
  }

  //Login function
  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  //Logout function
  logout(email, password) {
    return this.auth.signOut();
  }
  //Reset password function
  resetPassword(email) {
    return this.auth.sendPasswordResetEmail(email);
  }
}

//initialize and export
const firebase = new Firebase();
export default firebase;
