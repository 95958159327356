import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../components/Layout";
import ContactsTable from "../../components/ContactsTable";
import { getLeadsByLodge, getLeadsFromDB } from "../../utils/utils";
import AdminBoard from "../../components/AdminBoard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { getLodges } from "../../utils/utils";
import BoardContainer from "../../components/Board";
const AdminDashboard = () => {
  const [contacts, setContacts] = useState(null);
  const [lodges, setLodges] = useState(null);
  const [selected, setSelected] = useState("grid");
  const [lodgeId, setLodgeId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [selectedLodge, setSelectedLodge] = useState(null);

  useEffect(() => {
    handleGetContacts();
    handleGetLodges();
  }, [selectedLodge]);

  const handleGetContacts = async () => {
    if (!selectedLodge) {
      const data = await getLeadsFromDB();
      console.log(data);
      setContacts(data);
    } else {
      const data = await getLeadsByLodge(selectedLodge.lodgeId);
      console.log(data);
      setContacts(data);
    }
  };

  const handleGetLodges = async () => {
    const lodges = await getLodges();
    setLodges(lodges);
    console.log(lodges);
  };

  const handleRenderBoard = () => {
    if (!selectedLodge) {
      return <AdminBoard />;
    } else {
      return <BoardContainer lodgeId={selectedLodge.lodgeId} />;
    }
  };
  return (
    <DashboardLayout>
      <h3 className="p-11 bg-hs-grey text-3xl">Admin Dashboard</h3>
      <div className="p-3">
        {lodges && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={lodges}
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.lodgeName}
            renderInput={(params) => (
              <TextField {...params} label="Lodges" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              setSelectedLodge(newValue);
              console.log(newValue);
            }}
          />
        )}
      </div>
      <div className="inline-flex pl-11 pb-0 pt-10">
        <button
          id="list"
          className={` text-gray-800 font-bold py-2 px-4 rounded-l ${
            selected === "list" ? "bg-gray-400" : "bg-gray-200"
          }`}
          onClick={(e) => setSelected("list")}
        >
          <FormatListBulletedIcon />
        </button>
        <button
          id="grid"
          onClick={(e) => setSelected("grid")}
          className={` text-gray-800 font-bold py-2 px-4 rounded-l ${
            selected === "grid" ? "bg-gray-400" : "bg-gray-200"
          }`}
        >
          <GridViewIcon />
        </button>
      </div>
      <div className="p-11">
        {contacts && contacts.length > 0 && selected === "grid"
          ? handleRenderBoard()
          : contacts &&
            contacts.length > 0 && <ContactsTable data={contacts} />}
        {contacts && contacts.length <= 0 && (
          <h4>No leads currently assigned to this lodge...</h4>
        )}
      </div>
    </DashboardLayout>
  );
};

export default AdminDashboard;
