import React from "react";
import CreateTemplatePopup from "../components/CreateTemplatePopup";
import { DashboardLayout } from "../components/Layout";
import { useParams } from "react-router-dom";
import {
  getEmailTemplates,
  getTemplateById,
  getTextTemplates,
} from "../utils/utils";
import { Card, CardContent } from "@material-ui/core";
import { CardActionArea } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditTemplatePopup from "../components/EditTemplatePopup";

const EmailTemplates = () => {
  const { lodgeId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [textTemplates, setTextTemplates] = React.useState([]);
  const [type, setType] = React.useState("email");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editType, setEditType] = React.useState("email");
  const [selectedEditTemplate, setSelectedEditTemplate] = React.useState(null);

  React.useEffect(() => {
    handleGetTemplates();
  }, [saving, selectedEditTemplate]);

  React.useEffect(() => {
    handleGetTemplates();
  }, []);

  const handleGetTemplates = async () => {
    const data = await getEmailTemplates(lodgeId);
    const textTempData = await getTextTemplates(lodgeId);
    setTemplates(data);
    setTextTemplates(textTempData);
  };

  const handleClickOpen = (type) => {
    if (type === "email") {
      setType("email");
    } else if (type === "text") {
      setType("text");
    }
    setOpen(true);
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setOpenEdit(false);
    setSelectedValue(value);
    handleGetTemplates();
  };

  const handleClickEdit = async (id, type) => {
    console.log(id);
    const data = await getTemplateById(id, type);
    setOpenEdit(true);
    setSelectedEditTemplate(data);
    setEditType(type);

    console.log(data);
  };

  return (
    <DashboardLayout>
      <div className="display-inline">
        <h3 className="p-11 text-left text-3xl w-30 bg-hs-grey shadow-md">
          COMMUNICATION TEMPLATES
        </h3>
      </div>
      <div className="p-11">
        <div>
          <h3 className="pb-10 text-3xl inline">EMAIL TEMPLATES</h3>
          <button
            className="rounded bg-blue-500 text-white p-3 ml-11 inline"
            onClick={() => handleClickOpen("email")}
          >
            Create New
          </button>
        </div>

        <div className="grid sm:grid-cols-3 grid-cols-1">
          {templates &&
            templates.map((template) => (
              <Card key={template.message} style={{ margin: "20px" }}>
                <div className="float-right pr-2">
                  <CardActionArea
                    onClick={() => handleClickEdit(template.id, "email")}
                  >
                    <EditIcon />
                  </CardActionArea>
                </div>

                <CardContent>
                  <p className="text-2xl pb-5">Name: {template.templateName}</p>
                  <p className="text-2xl pb-5">Subject: {template.subject}</p>
                  <p>Message: {template.message}</p>
                </CardContent>
              </Card>
            ))}
        </div>
      </div>

      <div className="p-11">
        <div>
          <h3 className="pb-10 text-3xl inline">TEXT TEMPLATES</h3>
          <button
            className="rounded bg-blue-500 text-white p-3 ml-11 inline"
            onClick={() => handleClickOpen("text")}
          >
            Create New
          </button>
        </div>

        <div className="grid sm:grid-cols-3 grid-cols-1">
          {textTemplates &&
            textTemplates.map((template) => (
              <Card key={template.message} style={{ margin: "20px" }}>
                <div className="float-right pr-2">
                  <CardActionArea
                    onClick={() => handleClickEdit(template.id, "text")}
                  >
                    <EditIcon />
                  </CardActionArea>
                </div>
                <CardContent>
                  <p className="text-2xl pb-5">Name: {template.templateName}</p>
                  <p>Message: {template.message}</p>
                </CardContent>
              </Card>
            ))}
        </div>
      </div>
      <CreateTemplatePopup
        open={open}
        onClose={handleClose}
        setSaving={setSaving}
        lodgeId={lodgeId}
        type={type}
      />
      {selectedEditTemplate && (
        <EditTemplatePopup
          open={openEdit}
          onClose={handleClose}
          setSaving={setSaving}
          selectedTemplate={selectedEditTemplate}
          type={editType}
          lodgeId={lodgeId}
        />
      )}
    </DashboardLayout>
  );
};

export default EmailTemplates;
