import { Button, Card, CardContent, Grid, TextField } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import React from "react";
import { copyLeadForm } from "../embed/EmbedForm";
import { css } from "glamor";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import { blue } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import {
  getAdminNotifs,
  getEmailReciepients,
  handleAddEmailReciepient,
  handleAddAdminEmailReciepient,
  deleteEmailFromArray,
} from "../utils/utils";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { getFormsFromDb } from "../utils/utils";
import { copyLeadFormTwo } from "../embed/EmbedForm2";
import { adminCopyLeadForm } from "../embed/AdminEmbedForm1";
import { adminCopyLeadFormTwo } from "../embed/AdminEmbedForm2";

function SimpleDialog(props) {
  let { lodgeId } = useParams();
  console.log(lodgeId);
  const { onClose, selectedValue, open } = props;
  const [emails, setEmails] = React.useState([]);
  const [addAccount, setAddAccount] = React.useState(false);
  const [addEmailText, setAddEmailText] = React.useState("");

  React.useEffect(() => {
    handleGetEmails();
  }, [lodgeId, props.formId]);

  const handleGetEmails = async () => {
    const data = await getAdminNotifs(props.formId);
    setEmails(data);
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    if (value === "addAccount") {
      setAddAccount(true);
    }
  };

  const handleAddEmail = async () => {
    console.log(addEmailText);

    await handleAddAdminEmailReciepient(props.formId, addEmailText);
    setAddEmailText("");
    handleGetEmails();
  };

  const handleDeleteEmail = async (email) => {
    //console.log(email);
    await deleteEmailFromArray(email, props.formId);
    handleGetEmails();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Internal Email Notification Settings</DialogTitle>
      <p className="p-5">
        {" "}
        When a new form is filled out, the following addresses will be notified.
      </p>
      <div className="p-5">
        <List sx={{ pt: 0 }}>
          {emails.map((email) => (
            <div className="m-2">
              <ListItem button key={email}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={email} />
                <CloseIcon onClick={() => handleDeleteEmail(email)} />
              </ListItem>
            </div>
          ))}
          <div className="m-2">
            <ListItem
              autoFocus
              button
              onClick={() => handleListItemClick("addAccount")}
            >
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add account" />
            </ListItem>
          </div>
          {addAccount && (
            <>
              <TextField
                placeholder="enter email address"
                onChange={(e) => setAddEmailText(e.target.value)}
              />
              <Button onClick={handleAddEmail}>Add Email</Button>{" "}
            </>
          )}
        </List>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
};

const AdminForms = ({ lodgeId }) => {
  const [formIds, setFormIds] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [selectedForm, setSelectedForm] = React.useState(null);

  React.useEffect(() => {
    const handleGetFormIds = async () => {
      const data = await getFormsFromDb(lodgeId);
      setFormIds(data);
      console.log(data);
    };

    handleGetFormIds();
  }, [lodgeId]);

  const handleClickOpen = (formId) => {
    console.log("shoudl open");
    setOpen(true);
    setSelectedForm(formId);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleCopyLeadForm = (formId) => {
    let toastId = null;
    navigator.clipboard.writeText(adminCopyLeadForm(formId));
    if (!toast.isActive(toastId)) {
      console.log("Displaying Toast");
      toastId = toast("Code has been copied to your clipboard!", {
        className: css({
          background: "#00FF00 !important",
          color: "white !important",
          fontWeight: "bold",
        }),
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      console.log("Toast already active");
    }
  };

  const handleCopyLeadFormTwo = (formId) => {
    let toastId = null;
    navigator.clipboard.writeText(adminCopyLeadFormTwo(formId));
    if (!toast.isActive(toastId)) {
      console.log("Displaying Toast");
      toastId = toast("Code has been copied to your clipboard!", {
        className: css({
          background: "#00FF00 !important",
          color: "white !important",
          fontWeight: "bold",
        }),
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      console.log("Toast already active");
    }
  };
  return (
    <div className="">
      <h4 className="text-3xl text-left p-11 bg-hs-grey shadow-md items-center flex">
        ADMIN WEB FORMS
      </h4>
      <div className="p-11">
        <Grid container spacing={10}>
          <Grid item sm={3} lg={6} md={6}>
            <Card>
              <CardContent>
                <h4>Download E-Book</h4>
                <p>Preview</p>

                <br />
                <div className="m-2">
                  <TextField
                    placeholder="First Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Last Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Email Address"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCopyLeadFormTwo(formIds[1])}
                >
                  Copy Embed Code
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickOpen("14534")}
                  style={{ margin: "1%" }}
                >
                  Edit Reciepients
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/${lodgeId}/${formIds[1]}/edit-form`}
                  onClick={handleClickOpen}
                  style={{ margin: "1%" }}
                >
                  Edit Autoresponder
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={3} lg={6} md={6}>
            <Card>
              <CardContent>
                <h4>Schedule Consultation Form</h4>
                <p>Preview</p>
                <div className="m-2">
                  <TextField
                    placeholder="First Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Last Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Phone Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Address One"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>
                <div className="m-2">
                  <TextField
                    placeholder="Address Two"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="City"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="State"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="County"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Zip"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Are you male?"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="What is your age?"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Do you believe in a Supreme Being?"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Do you have a felony arrest record?"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="How did you learn about us?"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="m-2">
                  <TextField
                    placeholder="Tell us about yourself and your interest in Freemasonry."
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </div>

                <div className="pt-2" />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCopyLeadForm(formIds[0])}
                  style={{ margin: "1%" }}
                >
                  Copy Embed Code
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickOpen("1234")}
                  style={{ margin: "1%" }}
                >
                  Edit Reciepients
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/${lodgeId}/${formIds[0]}/edit-form`}
                  onClick={handleClickOpen}
                >
                  Edit Autoresponder
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <ToastContainer />
      {selectedForm && (
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
          formId={selectedForm}
        />
      )}
    </div>
  );
};

export default AdminForms;
