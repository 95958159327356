import React from "react";
import { DashboardLayout } from "../components/Layout";
import {
  AnnotationIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const features = [
  {
    name: "Quick and Easy",
    description:
      "Just upload your list, type out a message, and hit send. It's that easy.",
    icon: GlobeAltIcon,
  },
  {
    name: "No hidden fees",
    description:
      "Pay $X/m for unlimited messages, or pay by message sent. It's up to you. ",
    icon: ScaleIcon,
  },
  {
    name: "Fast and Secure",
    description:
      "If you choose not to save your list, it will be removed completely from our system once it has been sent.",
    icon: LightningBoltIcon,
  },
  {
    name: "More Features",
    description: "We are constantly working on changes and updates.",
    icon: AnnotationIcon,
  },
];

const MassSMSTool = () => {
  const { lodgeId } = useParams();
  return (
    <DashboardLayout>
      <div className="text-center text-3xl pt-20">COMING SOON!</div>
    </DashboardLayout>
  );
};

export default MassSMSTool;
