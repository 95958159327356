import { Card, CardContent, Grid } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import UserContext from "../contexts/UserContext";
import { getLeadsByLodge, getUser } from "../utils/utils";
import "../css/Reports.css";
import { DashboardLayout } from "../components/Layout";

const Reports = () => {
  const { user } = useContext(UserContext);
  const [leadsInfo, setLeadsInfo] = useState(null);
  useEffect(() => {
    if (!user) return;
    handleReportSetup();
  }, [user]);

  const handleReportSetup = async () => {
    const userInfo = await getUser(user.uid);
    console.log(userInfo);
    const leadsInfo = await getLeadsByLodge(userInfo[0].lodgeId);
    console.log(leadsInfo);
    setLeadsInfo(leadsInfo);
  };
  return (
    <DashboardLayout>
      <h1 className="p-11 text-3xl text-left bg-hs-grey shadow-md">
        LODGE REPORTS
      </h1>
      <Grid container justify="left" spacing={5} style={{ padding: "20px" }}>
        <Grid item>
          <Card>
            <CardContent>
              Current Leads Assigned to your lodge:{" "}
              <p>{leadsInfo && leadsInfo.length}</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
              Current Leads Assigned to you:{" "}
              <p>{leadsInfo && leadsInfo.length}</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
              Current Leads Assigned to you:{" "}
              <p>{leadsInfo && leadsInfo.length}</p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Reports;
