export function copyLeadFormTwo(lodgeId, formId) {
  return `
      <!-- add a container, where you want to include react components -->
      <div id="injected-react-content"></div>
      
      <!-- import the react libraray -->
      <script src="https://unpkg.com/react@16/umd/react.development.js" crossorigin></script>
      <script src="https://unpkg.com/react-dom@16/umd/react-dom.development.js" crossorigin></script>
      <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>
      
      <script
        src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
        crossorigin></script>
      
      
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossorigin="anonymous"
      />
      
      <!-- setup react root component other components -->
      <script type="text/babel">
      
      function uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
      
      async function postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
      
      return response.json(); // parses JSON response into native JavaScript objects
          }
      
          class RootComponent extends React.Component {
              render(){
                  return <div>
                      <MyComponent />
                  </div>;
              }
          }
      
          class MyComponent extends React.Component {
      
            // Example POST method implementation:
      
      
      
      
              handleSubmit(event) {
                  event.preventDefault();
                 let target   = event.target;
                 let formData = {
                   // need to randomize
                   leadId: uuid(),
                   status: "ebook",
                   assignedLodge: "${lodgeId}",
                   assignedDistrict: "Fayette",
                   formId: "${formId}"
                 };
                 const url = "https://us-central1-freemasons-a9cdf.cloudfunctions.net/submitForm";
      
                  for (let i = 0; i < target.length; i++) {
                      //console.log(event.target[i].name);
                      if(event.target[i].name !== "button") {
                         formData[target.elements[i].getAttribute("name")] = target.elements[i].value;
                      }
      
                      }
                  console.log('formData', formData);
                  postData(url, formData).then(data => {
                    console.log(data);
                  });
              }
      
              render(){
                  return (
                      <div>
                      <ReactBootstrap.Form onSubmit={this.handleSubmit}>
      
      
                        <ReactBootstrap.Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <ReactBootstrap.Form.Label>Full Name</ReactBootstrap.Form.Label>
                      <ReactBootstrap.Form.Control name="fullName" type="name" placeholder="Full Name" />
                      </ReactBootstrap.Form.Group>
      
                      <ReactBootstrap.Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <ReactBootstrap.Form.Label>Email Address</ReactBootstrap.Form.Label>
                      <ReactBootstrap.Form.Control name="email" type="email" placeholder="name@example.com" />
                      </ReactBootstrap.Form.Group>
      
                      <ReactBootstrap.Button name="button" variant="primary" type="submit">Submit</ReactBootstrap.Button>
      
                      </ReactBootstrap.Form>
                      </div>
      
                      );
              }
          }
      
          const domContainer = document.querySelector('#injected-react-content');
          ReactDOM.render( React.createElement(RootComponent), domContainer );
      </script>`;
}
