import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { TextField } from "@mui/material";
import { saveTemplateToDb, updateTemplateToDb } from "../utils/utils";

const emails = ["username@gmail.com", "user02@gmail.com"];

const EditTemplatePopup = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    setSaving,
    lodgeId,
    type,
    selectedTemplate,
  } = props;
  const [subject, setSubject] = React.useState(selectedTemplate.subject || "");
  const [message, setMessage] = React.useState(selectedTemplate.message || "");
  const [templateName, setTemplateName] = React.useState(
    selectedTemplate.templateName || ""
  );

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleSaveTemplate = async () => {
    console.log(subject, message);
    await updateTemplateToDb(
      lodgeId,
      subject,
      message,
      templateName,
      type,
      selectedTemplate.id
    );
    setSaving(true);
    onClose();
  };

  if (type === "email") {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Edit Template</DialogTitle>
        <div className="p-20 display-inline-flex align-top">
          <p className="mb-2">
            <TextField
              label="Template Name"
              fullWidth
              onChange={(e) => setTemplateName(e.target.value)}
              defaultValue={selectedTemplate.templateName}
            />
          </p>
          <p className="mb-2">
            <TextField
              label="Subject"
              fullWidth
              onChange={(e) => setSubject(e.target.value)}
              defaultValue={selectedTemplate.subject}
            />
          </p>
          <p className="mb-2">
            <TextField
              label="Message"
              multiline
              rows={6}
              fullWidth
              onChange={(e) => setMessage(e.target.value)}
              defaultValue={selectedTemplate.message}
            />
          </p>

          <p className="mb-2">
            {" "}
            <Button variant="contained" fullWidth onClick={handleSaveTemplate}>
              Save Changes
            </Button>
          </p>
        </div>
      </Dialog>
    );
  } else if (type === "text") {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Edit Template</DialogTitle>
        <div className="p-20 display-inline-flex align-top">
          <p className="m-2">
            {" "}
            <TextField
              label="Template Name"
              fullWidth
              onChange={(e) => setTemplateName(e.target.value)}
              defaultValue={selectedTemplate.templateName}
            />
          </p>

          <p className="m-2">
            <TextField
              label="Message"
              multiline
              rows={6}
              fullWidth
              onChange={(e) => setMessage(e.target.value)}
              defaultValue={selectedTemplate.message}
            />
          </p>

          <p className="m-2">
            {" "}
            <Button variant="contained" fullWidth onClick={handleSaveTemplate}>
              Save Changes
            </Button>
          </p>
        </div>
      </Dialog>
    );
  }
};

export default EditTemplatePopup;
