import { useState } from "react";
import { FormControl, Button } from "@material-ui/core";
import { TextField } from "@mui/material";
import React from "react";
import { DashboardLayout } from "../components/Layout";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const FeatureRequest = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const handleSendMessage = async () => {
    //! send request for email
    setSending(true);
    const body = {
      email: email,
      message: message,
      name: name,
    };

    const response = await axios.post(
      "https://us-central1-freemasons-a9cdf.cloudfunctions.net/sendSupportEmail",
      body
    );
    toast.success("Your Message has been sent!");
    setSending(false);
  };
  return (
    <DashboardLayout>
      <h3 className="p-11 bg-hs-grey text-3xl">Request a Feature</h3>
      <p className="text-center p-11 text-2xl">
        Please send us a message with details about your feature request and we
        will respond ASAP.
      </p>
      <div style={{ width: "50%", margin: "auto" }}>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Email Address"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Message"
          multiline
          rows={6}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          disabled={sending}
        >
          Send Message
        </Button>
      </div>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default FeatureRequest;
