import React, { useState } from "react";
import firebase from "../../firebase";
import { TextField, Button } from "@material-ui/core";
import "../../css/Signup.css";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://lexingtonlodge1.org/">
        Freemason CRM
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const Signup = (props) => {
  const [busy, setBusy] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
  });
  async function authenticateUser() {
    const { name, email, password } = values;
    setBusy(true);

    try {
      await firebase.register(name, email, password);
      props.history.push("/dashboard");
    } catch (err) {
      console.log(err);
    }
    setBusy(false);
  }

  const handleChange = (e) => {
    setValues((previousValues) => ({
      ...previousValues,
      [e.target.id]: e.target.value,
    }));
    console.log(values);
  };
  return (
    <div className="signup">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <Box
              component="form"
              onSubmit={authenticateUser}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={handleChange}
                variant="outlined"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                variant="outlined"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                variant="outlined"
              />
              <Button
                onClick={authenticateUser}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={busy}
                color="primary"
              >
                Create Account
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Signup;
