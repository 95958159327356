import React, { useEffect, useState, useContext } from "react";
import Board from "react-trello";
import LeadPopup from "./LeadPopup";
import {
  getLeadsByLodge,
  getLeadsFromDB,
  getUser,
  handleSaveActivity,
} from "../utils/utils";
import firebase from "../firebase";
import UserContext from "../contexts/UserContext";
import { CSVLink, CSVDownload } from "react-csv";
import { Dialog, TextField, Button } from "@mui/material";

function AdminBoard({ lodgeId, selectedValue }) {
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [leads, setLeads] = useState(null);
  const [cardLeads, setCardLeads] = useState(null);
  const [moveOpen, setMoveOpen] = useState(false);
  const [moveToLane, setMoveToLane] = useState("");
  const [moveFromLane, setMoveFromLane] = useState("");
  const [moveId, setMoveId] = useState("");

  const [totalLeadsCount, setTotalLeadsCount] = useState(0);
  const [boardData, setBoardData] = useState(null);
  const [moveNotes, setMoveNotes] = useState("");

  const [selectedCard, setSelectedCard] = useState("");
  let eventBus = null;

  useEffect(() => {
    if (!user) return;
    handleGetLeads();
  }, [user, selectedValue]);

  useEffect(() => {
    if (!leads) return;
    handleAddToBoard();
  }, [leads, selectedValue]);

  const handleGetLeads = async () => {
    const data = await getLeadsFromDB();
    //console.log(data);
    setLeads(data);
    setTotalLeadsCount(data.length);
  };

  const handleMovePopup = (fromLane, toLane, leadId) => {
    setMoveOpen(true);
    setMoveToLane(toLane);
    setMoveFromLane(fromLane);
    setMoveId(leadId);
  };

  const handleMoveClose = () => {
    setMoveOpen(false);
  };

  const handleCardMove = async (fromLaneId, toLaneId, cardId, index) => {
    handleMovePopup(fromLaneId, toLaneId, cardId);
    console.log(fromLaneId, toLaneId);
    const docRef = firebase.db
      .collection("leads")
      .where("leadId", "==", `${cardId}`);
    const snapshot = await docRef.get();
    if (snapshot.empty) {
      console.log("no card to move.");
      return;
    }

    snapshot.docs.forEach((doc) => {
      //console.log(doc.id);
      firebase.db.collection("leads").doc(doc.id).update(
        {
          status: toLaneId,
        },
        { merge: true }
      );
    });
    handleGetLeads();
  };

  let cards = [];

  const handleAddToBoard = () => {
    leads.forEach((lead) => {
      cards.push({
        id: lead.leadId.toString(),
        title: lead.firstName + " " + lead.lastName,
        status: lead.status,
      });
    });

    setCardLeads(cards);
  };

  const handleSaveMove = async () => {
    console.log(moveToLane, moveFromLane, moveNotes, moveId);
    const data = await handleSaveActivity(
      moveFromLane,
      moveToLane,
      moveId,
      moveNotes
    );
    setMoveOpen(false);
  };

  const data = {
    lanes: [
      {
        id: "ebook",
        title: "Downloaded E-Book",
        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "ebook";
          }),
      },
      {
        id: "consultation",
        title: "Requested Consultation",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "consultation";
          }),
      },
      {
        id: "leftMessage",
        title: "Left Message For Contact",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "leftMessage";
          }),
      },
      {
        id: "spokeWith",
        title: "Spoke With Contact",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "spokeWith";
          }),
      },
      {
        id: "invited",
        title: "Invited To Lodge",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "invited";
          }),
      },
      {
        id: "visited",
        title: "Visited Lodge",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "visited";
          }),
      },
      {
        id: "requestedPetition",
        title: "Requested Petition",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "requestedPetition";
          }),
      },
      {
        id: "petitionSubmitted",
        title: "Petition Submitted",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "petitionSubmitted";
          }),
      },

      {
        id: "investigationAssigned",
        title: "Investigation Committee Assigned",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "investigationAssigned";
          }),
      },

      {
        id: "investigationComplete",
        title: "Investigation Completed",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "investigationComplete";
          }),
      },

      {
        id: "ballotComplete",
        title: "Ballot Completed",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "ballotComplete";
          }),
      },
      {
        id: "eaInitiation",
        title: "EA Initiation Complete",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "eaInitiation";
          }),
      },
      {
        id: "eaProf",
        title: "EA Proficiency Complete",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "eaProf";
          }),
      },
      {
        id: "fcPassing",
        title: "FC Passing Complete",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "fcPassing";
          }),
      },

      {
        id: "fcProf",
        title: "FC Proficiency Complete",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "fcProf";
          }),
      },

      {
        id: "mmRaising",
        title: "MM Raising Complete",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "mmRaising";
          }),
      },
      {
        id: "mmProf",
        title: "MM Proficiency Complete",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "mmProf";
          }),
      },
      {
        id: "archived",
        title: "Contact Archived",

        cards:
          cardLeads &&
          cardLeads.filter((lead) => {
            return lead.status === "archived";
          }),
      },
    ],
  };

  const handleCardClick = (cardId, metadata, laneId) => {
    console.log(cardId, metadata);
    setSelectedCard(cardId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setEventBus = (handle) => {
    eventBus = handle;
  };

  return (
    <div className="board-container" style={{ paddingTop: "20px" }}>
      {leads && (
        <div className="pb-3">
          <CSVLink
            data={leads}
            filename={"contact-export.csv"}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
            </svg>
            <span>Export as CSV</span>
          </CSVLink>
        </div>
      )}
      <Board
        data={data}
        eventBusHandle={setEventBus}
        onCardClick={handleCardClick}
        onCardMoveAcrossLanes={handleCardMove}
        hideCardDeleteIcon={true}
        style={{
          backgroundColor: "white",
          width: "80vw",
          height: "70vh",
          overflow: "scroll",
          border: "2px solid black",
          padding: "10px",
        }}
      />
      <LeadPopup
        handleOpen={open}
        handleClose={handleClose}
        leadId={selectedCard}
      />
      <Dialog open={moveOpen}>
        <div className="p-11">
          <p className="p-11">
            Moving From {moveFromLane} --> {moveToLane}
          </p>
          <TextField
            multiline
            rows={6}
            placeholder="Notes"
            fullWidth
            onChange={(e) => setMoveNotes(e.target.value)}
          />
        </div>
        <Button variant="contained" color="primary" onClick={handleSaveMove}>
          Save Contact
        </Button>
      </Dialog>
    </div>
  );
}

export default AdminBoard;
