import React, { useEffect, useState } from "react";
import LeadPopup from "../components/LeadPopup";
import { getLodges, getUnassignedLeads } from "../utils/utils";
import "../css/Admin.css";
import Table from "../components/Table";
import { DashboardLayout } from "../components/Layout";

const Admin = () => {
  const [leads, setLeads] = useState(null);
  const [lodges, setLodges] = useState(null);
  const [selectedLodge, setSelectedLodge] = useState(null);
  const [open, setOpen] = useState(false);

  const handleGetLodges = async () => {
    const data = await getLodges();
    setLodges(data);
    console.log(data);
  };

  useEffect(() => {
    handleGetLeads();
    handleGetLodges();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetLeads = async () => {
    const data = await getUnassignedLeads();
    setLeads(data);
  };

  const clickhandler = (name) => {
    console.log(name);
  };
  return (
    <DashboardLayout>
      <div className="admin">
        <h3 className="p-11 bg-hs-grey text-3xl">Lodges</h3>
        <div className="p-11">
          {lodges && <Table data={lodges} click={clickhandler} />}
          <LeadPopup
            handleOpen={open}
            handleClose={handleClose}
            lodgeId={selectedLodge}
            type="admin"
            lodges={lodges}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Admin;
