const statuses = {
  ebook: "Downloaded E-Book",
  consultation: "Requested Consultation",
  leftMessage: "Left Message For Contact",
  spokeWith: "Spoke With Contact",
  invited: "Invited To Lodge",
  visited: "Visited Lodge",
  requestedPetition: "Requested Petition",
  petitionSubmitted: "Petition Submitted",
  investigationAssigned: "Investigation Committee Assigned",
  investigationComplete: "Investigation Completed",
  ballotComplete: "Ballot Completed",
  eaInitiation: "EA Initiation Complete",
  eaProf: "EA Proficiency Complete",
  fcPassing: "FC Passing Complete",
  fcProf: "FC Proficiency Complete",
  mmRaising: "MM Raising Complete",
  mmProf: "MM Profiency Complete",
  archived: "Contact Archived",
};

export default statuses;
