import React from "react";
import { DashboardLayout } from "../components/Layout";

const FAQ = () => {
  return (
    <DashboardLayout>
      <h2 className="p-11 bg-hs-grey text-3xl">FAQ's </h2>
      <p className="p-11">Coming Soon...</p>
    </DashboardLayout>
  );
};

export default FAQ;
