import firebase from "../firebase";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

//get all leads from the DB
//!todo add filter for state/lodge
export async function getLeadsFromDB() {
  let docArray = [];
  const docRef = firebase.db.collection("leads");
  const snapshot = await docRef.get();
  if (snapshot.empty) {
    console.log("no leads found");
    return;
  }

  snapshot.forEach((doc) => {
    docArray.push(doc.data());
  });

  return docArray;
}

export async function getLeadsByLodge(lodgeId) {
  const tempLeads = [];
  const docRef = firebase.db
    .collection("leads")
    .where("assignedLodge", "==", `${lodgeId}`);
  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.log("no leads found...");
    return [];
  }

  snapshot.docs.forEach((doc) => {
    tempLeads.push(doc.data());
  });

  return tempLeads;
}

//get a lead by a specific ID
export async function getLeadById(leadId) {
  let leadArr = [];
  const docRef = firebase.db
    .collection("leads")
    .where("leadId", "==", `${leadId}`);
  const data = await docRef.get();
  if (data.empty) {
    console.log("No leads found");
    return;
  } else {
    data.docs.forEach((doc) => {
      console.log(doc.data());
      leadArr.push(doc.data());
    });
  }
  //console.log(leadArr);
  return leadArr[0];
}

//get all leads that are not assigned to a lodge
export async function getUnassignedLeads() {
  let tempLeads = [];
  const docRef = firebase.db
    .collection("leads")
    .where("assignedLodge", "==", "");
  const snapshot = await docRef.get();
  if (snapshot.empty) {
    console.log("no unassigned leads");
    return;
  }
  snapshot.docs.forEach((doc) => {
    tempLeads.push(doc.data());
  });

  return tempLeads;
}

//add a new lead to the DB from form
export async function addNewLead(leadInfo) {
  const addRef = await firebase.db.collection("leads").add(leadInfo);
  return addRef;
}

export async function getLodges() {
  let tempLodges = [];
  const docRef = firebase.db.collection("lodges");
  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.log("no lodges found");
    return;
  }

  snapshot.docs.forEach((doc) => {
    tempLodges.push(doc.data());
  });

  return tempLodges;
}

export async function getLodgeById(lodgeId) {
  let tempLodge = [];
  const docRef = firebase.db
    .collection("lodges")
    .where("lodgeId", "==", `${lodgeId}`);

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.log("No lodges found with this ID.");
    return;
  }

  snapshot.docs.forEach((doc) => {
    tempLodge.push(doc.data());
  });

  if (tempLodge.length > 1) {
    console.log("Error, multiple lodges with the same ID.");
    return;
  }

  return tempLodge[0];
}

// update a prospect to a specfic lodge
export async function updateAssignedLodge(leadId, lodgeId) {
  const docRef = firebase.db
    .collection("leads")
    .where("leadId", "==", `${leadId}`);

  const lodgeRef = firebase.db
    .collection("lodges")
    .where("lodgeId", "==", `${lodgeId}`);

  const lodgeSnapshot = await lodgeRef.get();

  if (lodgeSnapshot.empty) {
    return console.log("no lodges to update");
  }
  const lodgeDoc = lodgeSnapshot.docs[0];
  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.log("no lead to update");
    return;
  }

  snapshot.docs.forEach(async (doc) => {
    console.log(doc.data());
    const toEmails = [
      lodgeDoc.data().masterEmail || "brian@btwebgroup.com",
      lodgeDoc.data().secEmail || "chris@btwebgroup.com",
    ];
    const body = {
      toEmails: toEmails,
    };

    const response = await axios.post(
      "https://us-central1-freemasons-a9cdf.cloudfunctions.net/sendEmailToLodge",
      body
    );
    firebase.db.collection("leads").doc(doc.id).update(
      {
        assignedLodge: lodgeId,
        lastUpdated: Date.now(),
      },
      { merge: true }
    );
  });

  return "lead assigned";
}

// update a prospect to a specfic status
export async function updateCurrentStatus(leadId, status) {
  const docRef = firebase.db
    .collection("leads")
    .where("leadId", "==", `${leadId}`);

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.log("no lead to update");
    return;
  }

  snapshot.docs.forEach((doc) => {
    firebase.db.collection("leads").doc(doc.id).update(
      {
        status: status,
        lastUpdated: Date.now(),
      },
      { merge: true }
    );
  });

  return "lead assigned";
}

// get a user from the db
export async function getUser(uid) {
  let tempUser = [];

  const docRef = firebase.db
    .collection("users")
    .where("userId", "==", `${uid}`);

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.log("no user found...");
    return;
  }

  snapshot.docs.forEach((doc) => {
    tempUser.push(doc.data());
  });

  return tempUser;
}

export async function getEmailReciepients(lodgeId) {
  let tempEmails = [];

  const docRef = firebase.db
    .collection("lodges")
    .where("lodgeId", "==", `${lodgeId}`);
  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.error("No lodges found...");
    return;
  }

  snapshot.docs.forEach((doc) => {
    const emailArray = doc.data().sendEmailsTo;
    tempEmails = emailArray;
  });

  return tempEmails;
}

export async function getAdminEmailReciepients(formId) {
  let tempEmails = [];

  const docRef = firebase.db
    .collection("forms")
    .where("formId", "==", `${formId}`);
  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.error("No lodges found...");
    return;
  }

  snapshot.docs.forEach((doc) => {
    const emailArray = doc.data().adminNotifs;
    tempEmails = emailArray;
  });

  return tempEmails;
}

export async function handleAddEmailReciepient(lodgeId, email) {
  let tempEmails = [];

  tempEmails.push(email);

  console.log(tempEmails);

  const docRef = firebase.db
    .collection("lodges")
    .where("lodgeId", "==", `${lodgeId}`);
  const snapshot = await docRef.get();

  if (snapshot.empty) {
    console.error("No lodges found...");
    return;
  }

  snapshot.docs.forEach((doc) => {
    const emailArray = doc.data().sendEmailsTo;
    tempEmails.push(...emailArray);
    console.log(tempEmails);
    firebase.db.collection("lodges").doc(doc.id).update(
      {
        sendEmailsTo: tempEmails,
      },
      { merge: true }
    );
  });
}

export async function saveEmailToLead(lodgeId, formId, htmlContent, subject) {
  // get lodge by id in db
  const docRef = firebase.db
    .collection("forms")
    .where("lodgeId", "==", `${lodgeId}`)
    .where("formId", "==", `${formId}`);

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    return console.log("couldnt find form...");
  }
  //if it does update the html content in form
  snapshot.docs.forEach((doc) => {
    firebase.db.collection("forms").doc(doc.id).update(
      {
        emailResponseHtml: htmlContent,
        lastUpdated: Date.now(),
        subject: subject,
      },
      { merge: true }
    );
  });

  return console.log("form html has been updated...");
  //if it doesn't...create new form
}

export async function getFormsFromDb(lodgeId) {
  const tempForms = [];

  const docRef = firebase.db
    .collection("lodges")
    .where("lodgeId", "==", `${lodgeId}`);

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    return console.log("No lodges found....");
  }

  snapshot.docs.forEach((doc) => {
    const formsArray = doc.data().forms;
    tempForms.push(...formsArray);
  });

  return tempForms;
}

export async function getCurrentEmail(lodgeId, formId) {
  // get document
  // get lodge by id in db
  const docRef = firebase.db
    .collection("forms")
    .where("lodgeId", "==", `${lodgeId}`)
    .where("formId", "==", `${formId}`);

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    return console.log("couldnt find form...");
  }

  // get reponsehtmlcontent
  const emailInfo = snapshot.docs[0].data();
  // return html from db
  return emailInfo;
}

export async function saveNote(type, id, content) {
  const idType = type === "leadNote" ? "leadId" : "lodgeId";
  const docRef = firebase.db.collection("notes");

  await docRef.add({
    type: type,
    [idType]: id,
    content: content,
    created: Date.now(),
  });

  return console.log("Note has been saved...");
}

export async function getNotes(type, id) {
  let tempNotes = [];
  const idType = type === "leadNote" ? "leadId" : "lodgeId";
  const docRef = firebase.db
    .collection("notes")
    .where("type", "==", `${type}`)
    .where(`${idType}`, "==", `${id}`)
    .orderBy("created", "desc");

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    return [];
  }

  snapshot.docs.forEach((doc) => {
    tempNotes.push(doc.data());
  });

  return tempNotes;
}

export async function saveTemplateToDb(
  lodgeId,
  subject,
  message,
  templateName,
  type
) {
  if (type === "email") {
    await firebase.db.collection("emailTemplates").add({
      id: uuidv4(),
      templateName: templateName,
      lodgeId: lodgeId,
      subject: subject,
      message: message,
      created: Date.now(),
    });
  } else if (type === "text") {
    await firebase.db.collection("textTemplates").add({
      id: uuidv4(),
      templateName: templateName,
      lodgeId: lodgeId,
      message: message,
      created: Date.now(),
    });
  }

  console.log("Template has been saved");
}

export async function updateTemplateToDb(
  lodgeId,
  subject,
  message,
  templateName,
  type,
  id
) {
  if (type === "email") {
    const docRef = firebase.db
      .collection("emailTemplates")
      .where("id", "==", `${id}`)
      .limit(1);

    const snapshot = await docRef.get();

    if (snapshot.empty) {
      return console.log("no templates found with this id...");
    }

    await firebase.db
      .collection("emailTemplates")
      .doc(snapshot.docs[0].id)
      .update(
        {
          id: id,
          templateName: templateName,
          lodgeId: lodgeId,
          subject: subject,
          message: message,
          lastUpdated: Date.now(),
        },
        { merge: true }
      );
  } else if (type === "text") {
    const docRef = firebase.db
      .collection("textTemplates")
      .where("id", "==", `${id}`)
      .limit(1);

    const snapshot = await docRef.get();

    if (snapshot.empty) {
      return console.log("no templates found with this id...");
    }

    await firebase.db
      .collection("textTemplates")
      .doc(snapshot.docs[0].id)
      .update(
        {
          id: id,
          templateName: templateName,
          lodgeId: lodgeId,
          message: message,
          lastUpdated: Date.now(),
        },
        { merge: true }
      );
  }

  console.log("Template has been saved");
}

export async function getEmailTemplates(lodgeId) {
  let tempDocs = [];
  const docRef = firebase.db
    .collection("emailTemplates")
    .where("lodgeId", "==", `${lodgeId}`);

  const snapshot = await docRef.get();

  if (!snapshot.empty) {
    snapshot.docs.forEach((doc) => {
      tempDocs.push(doc.data());
    });
  } else {
    return console.log("No templates found");
  }

  return tempDocs;
}

export async function getTextTemplates(lodgeId) {
  let tempDocs = [];
  const docRef = firebase.db
    .collection("textTemplates")
    .where("lodgeId", "==", `${lodgeId}`);

  const snapshot = await docRef.get();

  if (!snapshot.empty) {
    snapshot.docs.forEach((doc) => {
      tempDocs.push(doc.data());
    });
  } else {
    return console.log("No templates found");
  }

  return tempDocs;
}

export async function updateLodgeInfo(lodgeId, updatedInfo) {
  const docRef = await firebase.db
    .collection("lodges")
    .where("lodgeId", "==", `${lodgeId}`)
    .limit(1)
    .get();

  if (docRef.empty) {
    return console.log("do docs found...");
  }

  docRef.docs.forEach((doc) => {
    firebase.db.collection("lodges").doc(doc.id).update(
      {
        addressOne: updatedInfo.addressOne,
        addressTwo: updatedInfo.addressTwo,
        city: updatedInfo.city,
        phone: updatedInfo.phone,
        fromEmail: updatedInfo.email,
        lodgeName: updatedInfo.lodgeName,
        state: updatedInfo.state,
        zip: updatedInfo.zip,
        masterName: updatedInfo.masterName,
        masterEmail: updatedInfo.masterEmail,
        masterPhone: updatedInfo.masterPhone,
        secName: updatedInfo.secName,
        secEmail: updatedInfo.secEmail,
        secPhone: updatedInfo.secPhone,
        lastUpdated: Date.now(),
      },
      { merge: true }
    );
  });

  console.log("lodge info updated");
}

export async function updateLeadInfo(leadId, updatedInfo) {
  const docRef = await firebase.db
    .collection("leads")
    .where("leadId", "==", `${leadId}`)
    .limit(1)
    .get();

  if (docRef.empty) {
    return console.log("do docs found...");
  }

  docRef.docs.forEach((doc) => {
    firebase.db.collection("leads").doc(doc.id).update(
      {
        firstName: updatedInfo.firstName,
        lastName: updatedInfo.lastName,
        email: updatedInfo.email,
        phone: updatedInfo.phone,
        addressOne: updatedInfo.addressOne,
        addressTwo: updatedInfo.addressTwo,
        city: updatedInfo.city,
        state: updatedInfo.state,
        status: updatedInfo.status,
        zip: updatedInfo.zip,
        areYouMale: updatedInfo.areYouMale,
        age: updatedInfo.age,
        believeInGod: updatedInfo.believeInGod,
        felonies: updatedInfo.felonies,
        howDid: updatedInfo.howDid,
        aboutYou: updatedInfo.aboutYou,
        lastUpdated: Date.now(),
      },
      { merge: true }
    );
  });

  console.log("lead info updated");
}

export async function getLodgeTwilio(lodgeId) {
  let tempNum = "";
  const docRef = await firebase.db
    .collection("lodges")
    .where("lodgeId", "==", `${lodgeId}`)
    .limit(1)
    .get();

  if (docRef.empty) {
    return (tempNum = "Lodge not found");
  }

  docRef.docs.forEach((doc) => {
    tempNum = doc.data().twilioNumber;
  });

  return tempNum;
}

export async function handleLogout() {
  await firebase.logout();
}

export async function handleAddContactToDb(contactInfo, lodgeId) {
  const {
    firstName,
    lastName,
    email,
    phone,
    addressOne,
    addressTwo,
    city,
    state,
    zip,
    status,
  } = contactInfo;

  await firebase.db.collection("leads").add({
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    addressOne: addressOne,
    addressTwo: addressTwo,
    city: city,
    state: state,
    zip: zip,
    status: status,
    assignedLodge: lodgeId,
    leadId: uuidv4(),
    created: Date.now(),
  });
}

export async function addLodgesViaCsv(json) {
  console.log(json);
  try {
    Object.values(json).forEach((item) => {
      firebase.db.collection("lodges").add({
        lodgeName: item["B"],
        addressOne: item["C"],
        addressTwo: item["D"],
        city: item["E"],
        state: item["F"],
        zip: item["G"],
        districtId: item["H"],
        county: item["I"],
        masterName: item["J"] + " " + item["K"],
        masterEmail: item["L"],
        masterPhone: item["M"],
        secName: item["N"] + " " + item["O"],
        secEmail: item["P"],
        secPhone: item["Q"],
        emailHtml: "",
        forms: ["1234", "14534"],
        lodgeId: uuidv4(),
        sendEmailsTo: [],
        twilioNumber: "8592093414",
      });
      //console.log(item["B"], item["C"]);
    });
  } catch (error) {
    console.log(error);
  }
}

export async function handleAddAdminEmailReciepient(formId, recipient) {
  const tempNotifs = [];
  const docRef = firebase.db
    .collection("forms")
    .where("formId", "==", `${formId}`);
  const snapshot = await docRef.get();

  if (snapshot.empty) {
    return console.log("no forms found");
  }

  tempNotifs.push(recipient);

  snapshot.docs.forEach(async (doc) => {
    tempNotifs.push(...doc.data().adminNotifs);

    await firebase.db.collection("forms").doc(doc.id).update(
      {
        adminNotifs: tempNotifs,
      },
      { merge: true }
    );
  });

  return console.log("recips updated");
}

export async function getAdminNotifs(formId) {
  const tempAdminNotifs = [];

  const docRef = firebase.db
    .collection("forms")
    .where("formId", "==", `${formId}`)
    .limit(1);

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    return console.log("no form ids found");
  }

  snapshot.docs.forEach((doc) => {
    tempAdminNotifs.push(...doc.data().adminNotifs);
  });

  return tempAdminNotifs;
}

export async function handleSaveActivity(fromLane, toLane, leadId, notes) {
  const docRef = await firebase.db.collection("activity").add({
    fromLane: fromLane,
    toLane: toLane,
    leadId: leadId,
    notes: notes,
    created: Date.now(),
  });

  return console.log("Activity has been added....");
}

export async function getLeadActivityHistory(leadId) {
  const activity = [];

  const docRef = firebase.db
    .collection("activity")
    .where("leadId", "==", `${leadId}`);

  const snapshot = await docRef.get();

  if (snapshot.empty) {
    return console.log("no activity found...");
  }

  snapshot.docs.forEach((doc) => {
    activity.push(doc.data());
  });

  return activity;
}

export async function deleteEmailFromArray(email, formId) {
  const tempNotifs = [];
  const docRef = firebase.db
    .collection("forms")
    .where("formId", "==", `${formId}`);
  const snapshot = await docRef.get();

  if (snapshot.empty) {
    return console.log("no forms found");
  }

  snapshot.docs.forEach((doc) => {
    tempNotifs.push(...doc.data().adminNotifs);
    tempNotifs.splice(tempNotifs.indexOf(email), 1);

    firebase.db.collection("forms").doc(doc.id).update({
      adminNotifs: tempNotifs,
    });
  });

  return console.log("notifs updated...");
}

export async function getTemplateById(id, type) {
  if (type === "email") {
    const docRef = firebase.db
      .collection("emailTemplates")
      .where("id", "==", `${id}`)
      .limit(1);

    const snapshot = await docRef.get();

    if (snapshot.empty) {
      return console.log("no docs found");
    }

    return snapshot.docs[0].data();
  } else if (type === "text") {
    const docRef = firebase.db
      .collection("textTemplates")
      .where("id", "==", `${id}`)
      .limit(1);

    const snapshot = await docRef.get();

    if (snapshot.empty) {
      return console.log("no docs found");
    }

    return snapshot.docs[0].data();
  } else {
    return console.log("Not a valid type entry....");
  }
}
