import React, { useState, useEffect } from "react";
import ContactsTable from "../components/ContactsTable";
import { DashboardLayout } from "../components/Layout";
import { getLeadsByLodge } from "../utils/utils";
import { CSVLink, CSVDownload } from "react-csv";
import { useParams } from "react-router-dom";
import AddContactDialog from "../components/AddContactDialog";
const Contacts = () => {
  let { lodgeId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    handleGetContacts();
  }, []);

  useEffect(() => {
    handleGetContacts();
  }, [selectedValue]);

  const handleGetContacts = async () => {
    const data = await getLeadsByLodge(lodgeId);
    setContacts(data);
    console.log(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const clickhandler = (name) => {
    console.log(name);
  };

  return (
    <DashboardLayout>
      <h1 className="text-3xl text-left p-11 bg-hs-grey shadow-md">CONTACTS</h1>
      <div className="pt-11 pl-11">
        <button
          className=" p-3 rounded-sm bg-blue-500 text-white"
          onClick={handleClickOpen}
        >
          ADD A CONTACT
        </button>
      </div>

      {contacts && (
        <div className="pt-5 pl-11 pb-0">
          <CSVLink
            data={contacts}
            filename={"contact-export.csv"}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
            </svg>
            <span>Export as CSV</span>
          </CSVLink>
        </div>
      )}
      {contacts && (
        <div className="p-5">
          <ContactsTable data={contacts} clickhandler={clickhandler} />
        </div>
      )}
      <AddContactDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        lodgeId={lodgeId}
        setSelectedValue={setSelectedValue}
      />
    </DashboardLayout>
  );
};

export default Contacts;
