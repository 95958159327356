import React from "react";
import { handleLogout } from "../../utils/utils";
import { useHistory } from "react-router-dom";

const Logout = () => {
  let history = useHistory();
  React.useEffect(() => {
    const Logout = async () => {
      const status = await handleLogout();
      history.push("/login");
    };

    Logout();
  }, []);
  return <div></div>;
};

export default Logout;
