import { Navigation } from "react-minimal-side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import HelpIcon from "@mui/icons-material/Help";
import ContactsIcon from "@mui/icons-material/Contacts";
import React, { useState, useEffect, useContext } from "react";
import { getUser } from "../utils/utils";
import UserContext from "../contexts/UserContext";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MailIcon from "@mui/icons-material/Mail";
import BuildIcon from "@mui/icons-material/Build";
import "../css/index.css";
import logo from "../images/logo.png";

import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import Build from "@mui/icons-material/Build";
import LogoutIcon from "@mui/icons-material/Logout";

export const NavSidebar = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [lodgeId, setLodgeId] = useState(null);
  const [lodgeName, setLodgeName] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    if (!user) return;
    handleGetProfile();
  }, [user]);

  const handleGetProfile = async () => {
    const userInfo = await getUser(user.uid);
    console.log(userInfo[0].lodgeId);
    setLodgeId(userInfo[0].lodgeId);
    setLodgeName(userInfo[0].lodgeName);
    setUserType(userInfo[0].type);
  };

  if (userType === "admin") {
    return (
      <React.Fragment>
        {/* Sidebar Overlay */}
        <div
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className={`fixed inset-0 z-20 block transition-opacity lg:bg-hs-grey opacity-50 lg:hidden ${
            isSidebarOpen ? "block" : "hidden"
          }`}
        />

        <div>
          <button
            className="btn-menu bg-white lg:hidden xs:block"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            type="button"
          >
            <MenuIcon />
          </button>
        </div>

        {/* Sidebar */}
        <div
          className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:bg-hs-grey lg:static lg:inset-0 ${
            isSidebarOpen
              ? "ease-out translate-x-0"
              : "ease-in -translate-x-full"
          }`}
          style={{ backgroundColor: "#d6dbdf" }}
        >
          <div className="flex flex-col items-center justify-center mt-0 text-center py-3 pb-10 ">
            <div>
              <img src={logo} width="100px" />
            </div>

            <div>
              <span className="mx-2 text-2xl font-bold text-black">
                Grand Lodge
              </span>
            </div>
          </div>
          <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              if (itemId.length > 1) {
                history.push(itemId);
              }
            }}
            items={[
              {
                title: "Dashboard",
                itemId: "/admin/dashboard",
                // Optional
                elemBefore: () => <DashboardIcon />,
              },
              {
                title: "Lodges",
                itemId: "1",
                elemBefore: () => <AccountCircleIcon />,
                subNav: [
                  {
                    title: "List of Lodges",
                    itemId: `/admin`,
                    // Optional
                    elemBefore: () => <FormatListNumberedIcon />,
                  },
                  {
                    title: "Forms",
                    itemId: `/${lodgeId}/forms`,
                    // Optional
                    elemBefore: () => <FormatListNumberedIcon />,
                  },

                  {
                    title: "Reports",
                    itemId: "/reports",
                    elemBefore: () => <AssessmentIcon />,
                  },
                ],
              },
              {
                title: "Contacts",
                itemId: "2",
                elemBefore: () => <ContactsIcon />,
                subNav: [
                  {
                    title: "Unassigned Contacts",
                    itemId: `/contacts`,
                  },
                ],
              },

              {
                title: "Tools",
                itemId: "3",
                elemBefore: () => <BuildIcon />,
                subNav: [
                  {
                    title: "Mass SMS Tool",
                    itemId: `/${lodgeId}/mass-sms-tool`,
                  },
                ],
              },
            ]}
          />

          <div className="absolute bottom-0 w-full my-8">
            <Navigation
              activeItemId={location.pathname}
              items={[
                {
                  title: "Logout",
                  itemId: "/logout",
                  elemBefore: () => <LogoutIcon />,
                },
                {
                  title: "Support",
                  itemId: "4",
                  elemBefore: () => <HelpIcon />,
                  subNav: [
                    {
                      title: "Contact",
                      itemId: "/support/contact",
                      // Optional
                      // elemBefore: () => <Icon name="calendar" />
                    },
                    {
                      title: "FAQ",
                      itemId: "/support/faq",
                      // Optional
                      // elemBefore: () => <Icon name="calendar" />
                    },
                    {
                      title: "Feature Request",
                      itemId: "/support/request",
                      // Optional
                      // elemBefore: () => <Icon name="calendar" />
                    },
                  ],
                },
              ]}
              onSelect={({ itemId }) => {
                if (itemId.length > 1) {
                  history.push(itemId);
                }
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* Sidebar Overlay */}
        <div
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className={`fixed inset-0 z-20 block transition-opacity lg:bg-hs-grey opacity-50 lg:hidden ${
            isSidebarOpen ? "block" : "hidden"
          }`}
        />

        <div>
          <button
            className="btn-menu bg-white lg:hidden xs:block"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            type="button"
          >
            <MenuIcon />
          </button>
        </div>

        {/* Sidebar */}
        <div
          className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:bg-hs-grey lg:static lg:inset-0 ${
            isSidebarOpen
              ? "ease-out translate-x-0"
              : "ease-in -translate-x-full"
          }`}
          style={{ backgroundColor: "#d6dbdf" }}
        >
          <div className="flex flex-col items-center justify-center mt-0 text-center py-3 pb-10 ">
            <div>
              <img src={logo} width="100px" />
            </div>

            <div>
              <span className="mx-2 font-bold text-black">
                {lodgeName && lodgeName}
              </span>
            </div>
          </div>
          <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              history.push(itemId);
            }}
            items={[
              {
                title: "Home",
                itemId: "/home",
                // Optional
                elemBefore: () => <HomeIcon />,
              },
              {
                title: "Pipeline",
                itemId: "/dashboard",
                // Optional
                elemBefore: () => <DashboardIcon />,
              },
              {
                title: "Lodge Profile",
                itemId: `/lodge/${lodgeId}`,
                elemBefore: () => <AccountCircleIcon />,
                subNav: [
                  /* {
                    title: "Forms",
                    itemId: `/${lodgeId}/forms`,
                    // Optional
                    elemBefore: () => <FormatListNumberedIcon />,
                  }, */
                  {
                    title: "Templates",
                    itemId: `/${lodgeId}/templates`,
                    // Optional
                    elemBefore: () => <MailIcon />,
                  },
                  {
                    title: "Reports",
                    itemId: "/reports",
                    elemBefore: () => <AssessmentIcon />,
                  },
                ],
              },
              {
                title: "Contacts",
                itemId: `/${lodgeId}/contacts`,
                elemBefore: () => <ContactsIcon />,
                subNav: [
                  {
                    title: "Add A Contact",
                    itemId: `/${lodgeId}/add-contact`,
                  },
                ],
              },

              {
                title: "Tools",

                elemBefore: () => <BuildIcon />,
                subNav: [
                  {
                    title: "Mass SMS Tool",
                    itemId: `/${lodgeId}/mass-sms-tool`,
                  },
                ],
              },
            ]}
          />

          <div className="absolute bottom-0 w-full my-8">
            <Navigation
              activeItemId={location.pathname}
              items={[
                {
                  title: "Logout",
                  itemId: "/logout",
                  elemBefore: () => <LogoutIcon />,
                },
                {
                  title: "Support",
                  elemBefore: () => <HelpIcon />,
                  subNav: [
                    {
                      title: "Contact",
                      itemId: "/support/contact",
                      // Optional
                      // elemBefore: () => <Icon name="calendar" />
                    },
                    {
                      title: "FAQ",
                      itemId: "/support/faq",
                      // Optional
                      // elemBefore: () => <Icon name="calendar" />
                    },
                    {
                      title: "Feature Request",
                      itemId: "/support/request",
                      // Optional
                      // elemBefore: () => <Icon name="calendar" />
                    },
                  ],
                },
              ]}
              onSelect={({ itemId }) => {
                history.push(itemId);
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
};
