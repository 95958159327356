import React, { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getCurrentEmail, saveEmailToLead } from "../utils/utils";
import { DashboardLayout } from "../components/Layout";
import { toast, ToastContainer } from "react-toastify";
import { TextField } from "@mui/material";

const EditForm = () => {
  let { lodgeId, formId } = useParams();
  const [editorState, setOnEditorStateChange] = React.useState("");
  const [subject, setSubject] = React.useState("");

  const handleEditorStateChange = (e) => {
    setOnEditorStateChange(e);
    console.log(e);
  };

  useEffect(() => {
    const handleGetCurrentEmail = async () => {
      const htmlData = await getCurrentEmail(lodgeId, formId);
      console.log(htmlData.subject);
      setSubject(htmlData.subject);
      console.log(htmlData.emailResponseHtml);
      const blocksFromHtml = htmlToDraft(htmlData.emailResponseHtml);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setOnEditorStateChange(EditorState.createWithContent(contentState));
    };

    handleGetCurrentEmail();
  }, [lodgeId, formId]);

  const handleSaveEmail = () => {
    const htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    try {
      saveEmailToLead(lodgeId, formId, htmlContent, subject);
      toast.success("Autoresponder has been saved...");
      console.log("saved email to lead...");
    } catch (error) {
      toast.error("Could not save...");
      console.error(error);
    }
  };
  return (
    <DashboardLayout>
      <div className="p-11">
        {subject !== "" && (
          <TextField
            defaultValue={subject}
            label="Subject"
            fullWidth
            onChange={(e) => setSubject(e.target.value)}
          />
        )}
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={handleEditorStateChange}
        />
        <br />
        <Button variant="contained" color="primary" onClick={handleSaveEmail}>
          Save Email
        </Button>
      </div>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default EditForm;
