import React, { useState, useContext, useEffect } from "react";
import BoardContainer from "../components/Board";
import { DashboardLayout } from "../components/Layout";
import UserContext from "../contexts/UserContext";
import { getLodgeById, getUser } from "../utils/utils";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import ContactsListView from "../components/ContactListView";
import AddContactDialog from "../components/AddContactDialog";

const Dashboard = (props) => {
  const { user } = useContext(UserContext);
  const [lodgeName, setLodgeName] = useState("");
  const [selected, setSelected] = useState("grid");
  const [lodgeId, setLodgeId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  useEffect(() => {
    if (!user) {
      return;
    }
    handleGetLodgeName();
  }, [user]);

  const handleGetLodgeName = async () => {
    const userData = await getUser(user.uid);
    const lodgeData = await getLodgeById(userData[0].lodgeId);
    setLodgeId(userData[0].lodgeId);
    setLodgeName(lodgeData.lodgeName);
  };
  return (
    <DashboardLayout>
      <h1 className="text-3xl text-left  p-11 bg-hs-grey shadow-md items-center flex">
        PIPELINE
      </h1>
      <div className="pt-5">
        <h3 className="text-left pl-5 pt-5 inline">
          Here are the Contacts in your Pipeline:
        </h3>
        <div className="inline p-5">
          <button
            className=" p-3 rounded-sm bg-blue-500 text-white"
            onClick={handleClickOpen}
          >
            ADD A CONTACT
          </button>
        </div>
      </div>

      <div className="inline-flex pl-11 pb-0 pt-10">
        <button
          id="list"
          className={` text-gray-800 font-bold py-2 px-4 rounded-l ${
            selected === "list" ? "bg-gray-400" : "bg-gray-200"
          }`}
          onClick={(e) => setSelected("list")}
        >
          <FormatListBulletedIcon />
        </button>
        <button
          id="grid"
          onClick={(e) => setSelected("grid")}
          className={` text-gray-800 font-bold py-2 px-4 rounded-l ${
            selected === "grid" ? "bg-gray-400" : "bg-gray-200"
          }`}
        >
          <GridViewIcon />
        </button>
      </div>
      <div className="pt-0 p-11">
        {lodgeId && selected === "grid" ? (
          <BoardContainer lodgeId={lodgeId} selectedValue={selectedValue} />
        ) : (
          <ContactsListView lodgeId={lodgeId} selectedValue={selectedValue} />
        )}
      </div>
      <AddContactDialog
        open={open}
        onClose={handleClose}
        selectedValue={selectedValue}
        lodgeId={lodgeId}
        setSelectedValue={setSelectedValue}
      />
    </DashboardLayout>
  );
};

export default Dashboard;
