import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import {
  getLeadsByLodge,
  getLodgeById,
  getUser,
  updateLodgeInfo,
} from "../utils/utils";
import "../css/Lodge.css";
import { Card, CardContent, TextField, Button, Grid } from "@material-ui/core";
import { getNotes, saveNote } from "../utils/utils";
import { DashboardLayout } from "../components/Layout";
import { toast, ToastContainer } from "react-toastify";
import Table from "../components/Table";
import ContactsTable from "../components/ContactsTable";
import UserContext from "../contexts/UserContext";

const Lodge = () => {
  const { lodgeId } = useParams();
  const { user } = useContext(UserContext);
  const [lodge, setLodge] = useState(null);
  const [notes, setNotes] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [showSave, setShowSave] = useState(false);
  const [leads, setLeads] = useState([]);
  const [userInfo, setUserInfo] = useState("");
  const [updatedLodgeInfo, setUpdatedLodgeInfo] = useState({
    lodgeName: "",
    phone: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    masterName: "",
    masterEmail: "",
    masterPhone: "",
    secName: "",
    secEmail: "",
    secPhone: "",
  });

  useEffect(() => {
    if (!user) return;
    handleGetUser();
    handleGetLodgeData();
    handleGetNotes();
    handleGetLeads();
  }, [lodgeId, user]);

  const handleGetLodgeData = async () => {
    const data = await getLodgeById(lodgeId);
    setLodge(data);
    setUpdatedLodgeInfo({
      lodgeName: data.lodgeName || "",
      email: data.fromEmail || "",
      phone: data.phone || "",
      addressOne: data.addressOne || "",
      addressTwo: data.addressTwo || "",
      city: data.city || "",
      state: data.state || "",
      zip: data.zip || "",
      masterName: data.masterName || "",
      masterEmail: data.masterEmail || "",
      masterPhone: data.masterPhone || "",
      secName: data.secName || "",
      secEmail: data.secEmail || "",
      secPhone: data.secPhone || "",
    });
  };

  const handleGetUser = async () => {
    const data = await getUser(user.uid);
    setUserInfo(data[0]);
  };

  const handleGetLeads = async () => {
    const data = await getLeadsByLodge(lodgeId);
    setLeads(data);
    //console.log(data);
  };

  const handleGetNotes = async () => {
    const data = await getNotes("lodgeNote", lodgeId);
    setNotes(data);
  };

  const handleSaveNote = async () => {
    const savedNoteResponse = await saveNote("lodgeNote", lodgeId, newNote);

    console.log(savedNoteResponse);

    setNewNote("");

    await handleGetNotes();
  };

  const handleChange = (e) => {
    setShowSave(true);

    setUpdatedLodgeInfo((previousValues) => ({
      ...previousValues,
      [e.target.name]: e.target.value,
    }));

    console.log(updatedLodgeInfo);
  };

  const handleSave = async () => {
    console.log(updatedLodgeInfo);
    const saving = await updateLodgeInfo(lodgeId, updatedLodgeInfo);
    toast.success("Profile has been updated...");
  };

  const displayDateAsString = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <DashboardLayout>
      <h2 className="text-3xl text-left text-black p-11 bg-hs-grey shadow-md ">
        LODGE PROFILE
      </h2>
      <Grid container spacing={5} className="pl-10 pt-10" justify="left">
        <Grid item lg={6} sm={12}>
          <Card style={{ padding: "20px" }}>
            <CardContent>
              <h3 className="text-2xl pb-2">PROFILE INFO</h3>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="lodgeName"
                    defaultValue={lodge.lodgeName}
                    variant="outlined"
                    label="Lodge Name"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="email"
                    defaultValue={lodge.fromEmail}
                    variant="outlined"
                    label="Email Address"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="phone"
                    defaultValue={lodge.phone}
                    variant="outlined"
                    label="Phone Number"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="addressOne"
                    defaultValue={lodge.addressOne}
                    variant="outlined"
                    label="Address One"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="addressTwo"
                    defaultValue={lodge.addressTwo}
                    variant="outlined"
                    label="Address Two"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="city"
                    defaultValue={lodge.city}
                    variant="outlined"
                    label="City"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="state"
                    defaultValue={lodge.state}
                    variant="outlined"
                    label="State"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="zip"
                    defaultValue={lodge.zip}
                    variant="outlined"
                    label="Zip"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              {showSave && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  fullWidth
                >
                  Save Changes
                </Button>
              )}
            </CardContent>
          </Card>
          <div className="p-2"></div>
          <Card>
            <CardContent>
              <h3 className="text-2xl">Member Info</h3>
              <p className="pt-4">Master</p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="masterName"
                    defaultValue={lodge.masterName}
                    variant="outlined"
                    label="Master Name"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="masterEmail"
                    defaultValue={lodge.masterEmail}
                    variant="outlined"
                    label="Master Email"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="masterPhone"
                    defaultValue={lodge.masterPhone}
                    variant="outlined"
                    label="Master Phone"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="pt-4">Secretary</p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="secName"
                    defaultValue={lodge.secName}
                    variant="outlined"
                    label="Secretary Name"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="secEmail"
                    defaultValue={lodge.secEmail}
                    variant="outlined"
                    label="Secretary Email"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              <p className="m-3">
                {lodge && (
                  <TextField
                    name="secPhone"
                    defaultValue={lodge.masterPhone}
                    variant="outlined"
                    label="Secretary Phone"
                    onChange={handleChange}
                    fullWidth
                  />
                )}
              </p>
              {showSave && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  fullWidth
                >
                  Save Changes
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Card style={{ marginRight: "20px" }}>
            <CardContent>
              <div className="">
                <h3 className="text-2xl pb-2">ADD NOTE</h3>
                <TextField
                  label="Notes"
                  fullWidth
                  multiline
                  variant="outlined"
                  rows={4}
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                />
              </div>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveNote}
              >
                Save Note
              </Button>
            </CardContent>
          </Card>
          <div className="pb-10" />
          <Card style={{ marginRight: "1%" }}>
            <CardContent>
              <p className="text-2xl pb-3">NOTE HISTORY</p>
              <ol>
                {notes &&
                  notes.map((note) => (
                    <>
                      <li className="p-2" key={note.content}>
                        {note.content} | {displayDateAsString(note.created)}
                      </li>
                    </>
                  ))}
              </ol>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {userInfo && userInfo.type === "admin" && (
        <div className="p-11">
          <h3 className="text-2xl">Assigned Contacts</h3>
          {leads && <ContactsTable data={leads} />}
        </div>
      )}

      <ToastContainer />
    </DashboardLayout>
  );
};

export default Lodge;
