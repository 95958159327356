// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDN0AkaLllIFcCJAA6Bxep4WGqhyfV3J8g",
  authDomain: "freemasons-a9cdf.firebaseapp.com",
  projectId: "freemasons-a9cdf",
  storageBucket: "freemasons-a9cdf.appspot.com",
  messagingSenderId: "488110304178",
  appId: "1:488110304178:web:46425925f7b08f2aed12ab",
  measurementId: "G-V2BFDBQZNF",
};

export default firebaseConfig;
