import React from "react";
import CSVReader from "../../components/csvParser";
import { DashboardLayout } from "../../components/Layout";
import { addLodgesViaCsv } from "../../utils/utils";

const AddLodge = () => {
  const jsonArray = {
    "Submission Date": {
      B: "Lodge name and number:",
      C: "Street Address",
      D: "Street Address Line 2",
      E: "City",
      F: "State / Province",
      G: "Postal / Zip Code",
      H: "District Number:",
      I: "County of Lodge:",
      J: "Master's First Name",
      K: "Master's Last Name",
      L: "Master's email address:",
      M: "Master's phone number:",
      N: "Assistant First Name",
      O: "Assistant Last Name",
      P: "Email of the alternate Brother:",
      Q: "Phone number of assisting  Brother:",
    },
    "2021/12/30 13:01:10": {
      B: "Elkhorn 568",
      C: "1622 Elkhorn Rd",
      D: "",
      E: "Campbellsville",
      F: "Kentucky",
      G: "42718",
      H: "16",
      I: "Taylor",
      J: "Brandon",
      K: "Garrett",
      L: "Brandon.garrett@lindsey.edu",
      M: "(270) 789-7781",
      N: "Dave",
      O: "Gerhardt",
      P: "gear-head@live.com",
      Q: "(270) 405-4122",
    },
    "2021/12/30 10:41:09": {
      B: "Edmonton 931",
      C: "603 W. Stockton Street",
      D: "",
      E: "Edmonton",
      F: "Kentucky",
      G: "42129",
      H: "11",
      I: "Metcalfe",
      J: "Nick",
      K: "Brewster",
      L: "",
      M: "(270) 590-0985",
      N: "Daniel",
      O: "Glass",
      P: "danielglass0806@gmail.com",
      Q: "(270) 590-8980",
    },
    "2021/12/30 05:51:58": {
      B: "Robert M. Sirkle Lodge No.954",
      C: "553 Rosemont Garden",
      D: "",
      E: "Lexington",
      F: "KY",
      G: "40503",
      H: "20",
      I: "Fayette",
      J: "Alan",
      K: "Martin",
      L: "amartin@robertmsirkle954.org",
      M: "(859) 229-8873",
      N: "Roger",
      O: "Trent",
      P: "rltrent@twc.com",
      Q: "(859) 492-4496",
    },
    "2021/12/30 01:58:00": {
      B: "Good Faith Lodge #95",
      C: "1883 Mt. Zion Rd.",
      D: "",
      E: "Union",
      F: "KY",
      G: "41091",
      H: "18",
      I: "Boone",
      J: "Timothy",
      K: "Walker",
      L: "timothy.r.walker@gmail.com",
      M: "(307) 277-5304",
      N: "David",
      O: "Bird",
      P: "birddavid60@gmail.com",
      Q: "(859) 750-1892",
    },
    "2021/12/29 12:36:29": {
      B: "Simpson Benevolent #177",
      C: "724 Blackjack RD",
      D: "",
      E: "Franklin",
      F: "kentucky",
      G: "42134",
      H: "10",
      I: "Simpson",
      J: "Perry",
      K: "Wheeler",
      L: "twdocrx@yahoo.com",
      M: "(270) 791-8185",
      N: "James",
      O: "Goodrum",
      P: "james.goodrum53@gmail.com",
      Q: "(270) 776-7423",
    },
    "2021/12/29 10:58:10": {
      B: "Jerusalem 9",
      C: "461 Klutey Park Plaza Dr",
      D: "",
      E: "Henderson",
      F: "KY",
      G: "42420",
      H: "5",
      I: "Henderson",
      J: "John",
      K: "Cavanah",
      L: "John.Cavanah1@gmail.com",
      M: "(812) 774-0788",
      N: "Rob",
      O: "Toerne",
      P: "Rob@Toerne.net",
      Q: "(270) 860-2929",
    },
    "2021/12/28 23:44:58": {
      B: "Harlan Lodge 879",
      C: "112 W Central St",
      D: "",
      E: "Harlan",
      F: "Ky",
      G: "40831",
      H: "30",
      I: "Harlan",
      J: "Matthew",
      K: "Taylor",
      L: "tater28016@yahoo.com",
      M: "(606) 273-6474",
      N: "Stephen",
      O: "Morton",
      P: "smorton23@gmail.com",
      Q: "(606) 671-0351",
    },
    "2021/12/27 10:21:22": {
      B: "Richmond Lodge No. 25",
      C: "217 S. Porter Drive",
      D: "Suite 1",
      E: "Richmond",
      F: "KY",
      G: "40475",
      H: "25",
      I: "Madison",
      J: "Roger",
      K: "Hurt",
      L: "rfhurt@gmail.com",
      M: "(859) 314-1589",
      N: "David",
      O: "Saylor",
      P: "fd_saylor@att.net",
      Q: "(859) 200-4844",
    },
    "2021/12/26 15:42:34": {
      B: "Grahamville 707",
      C: "5755 Metropolis Lake Rd",
      D: "",
      E: "West Paducah",
      F: "KY",
      G: "42086",
      H: "1",
      I: "McCracken",
      J: "Jeff",
      K: "Wallace",
      L: "ukfan3@aol.com",
      M: "(270) 210-9579",
      N: "Casey",
      O: "Lynn",
      P: "calynn07@gmail.com",
      Q: "(270) 210-2784",
    },
    "2021/12/25 21:32:38": {
      B: "Vine Grove No. 603",
      C: "857 Crume Road",
      D: "P.O. Box 603",
      E: "Vine Grove",
      F: "KY",
      G: "40175",
      H: "9",
      I: "Hardin",
      J: "Michael",
      K: "Uhlig",
      L: "Michael.J.Uhlig@gmail.com270",
      M: "(270) 300-4601",
      N: "Charles",
      O: "Hagar",
      P: "Charles.o.Hagar.CIV@army.mil",
      Q: "(270) 300-0806",
    },
    "2021/12/23 19:48:24": {
      B: "buechel 896",
      C: "7300 Fegenbush Ln",
      D: "",
      E: "Louisville",
      F: "Kentucky",
      G: "40228",
      H: "14",
      I: "Jefferson",
      J: "John",
      K: "Cissell",
      L: "johncissell@gmail.com",
      M: "(502) 791-4508",
      N: "Jeff",
      O: "Lanzet",
      P: "jeff@lanzets.com",
      Q: "(336) 407-0055",
    },
    "2021/12/20 11:23:35": {
      B: "FERN CREEK # 955",
      C: "6919 Applegate Lane",
      D: "",
      E: "Louisville",
      F: "KY",
      G: "40291",
      H: "13",
      I: "Jefferson",
      J: "Calvin",
      K: "Watts502",
      L: "calvinwatts@rocketmail.com",
      M: "(502) 649-2244",
      N: "Luke",
      O: "Martin",
      P: "Lukester68@aol.com",
      Q: "(502) 599-1780",
    },
    "2021/12/17 19:15:59": {
      B: "St. Andrews No. 18",
      C: "128 N. Main Street",
      D: "",
      E: "Cynthiana",
      F: "Kentucky",
      G: "41031",
      H: "19",
      I: "Harrison",
      J: "Steven",
      K: "Lancaster",
      L: "lancaster.farm@yahoo.com",
      M: "(859) 229-0420",
      N: "",
      O: "",
      P: "",
      Q: "",
    },
    "2021/12/16 14:38:36": {
      B: "Dycusburg #232",
      C: "9733 st rt 70",
      D: "",
      E: "Dycusburg",
      F: "KY",
      G: "42037",
      H: "3",
      I: "Crittenden",
      J: "Kenny",
      K: "Woodall",
      L: "none@none.com",
      M: "(270) 963-8173",
      N: "LaFon",
      O: "Coomer",
      P: "olcoomer@gmail.com",
      Q: "(270) 963-5163",
    },
    "2021/12/16 07:37:21": {
      B: "Roberts 172",
      C: "PO box 1183 Fulton, Ky",
      D: "",
      E: "fulton",
      F: "ky",
      G: "42041",
      H: "1",
      I: "fulton",
      J: "Clint",
      K: "Wright",
      L: "cwright1977@live.com",
      M: "(731) 446-1546",
      N: "Mike",
      O: "Cash",
      P: "",
      Q: "(270) 727-0087",
    },
    "2021/12/15 16:25:14": {
      B: "Hart Lodge #61",
      C: "254 Edgewood Drive",
      D: "",
      E: "Nicholasville",
      F: "KY",
      G: "40356",
      H: "20",
      I: "Jessamine",
      J: "Barry",
      K: "Waldrop",
      L: "barrywaldrop@windstream.net",
      M: "(859) 553-6808",
      N: "Lee",
      O: "Crowe",
      P: "lbcrowe@yahoo.com",
      Q: "(859) 771-4287",
    },
    "2021/12/14 18:23:43": {
      B: "Hazard #676",
      C: "548 Main Street",
      D: "",
      E: "Hazard",
      F: "Kentucky",
      G: "41701",
      H: "29",
      I: "Perry",
      J: "Wesley",
      K: "Callahan",
      L: "wtcembalmer@hotmail.com",
      M: "(606) 233-2670",
      N: "Paul",
      O: "Johnson",
      P: "pjohnson_2@hotmail.com",
      Q: "(606) 216-4619",
    },
    "2021/12/10 14:40:20": {
      B: "Smith Lodge 775",
      C: "2506 Argillite Road,",
      D: "",
      E: "Flatwoods",
      F: "Kentucky",
      G: "41169",
      H: "27",
      I: "Greenup",
      J: "Duayne",
      K: "King",
      L: "Duayne1415@Windstream.net",
      M: "(606) 331-0165",
      N: "",
      O: "",
      P: "",
      Q: "",
    },
    "2021/12/08 19:52:17": {
      B: "New Zion lodge #651",
      C: "1293 McCammon Ridge RD",
      D: "",
      E: "Mckee",
      F: "KY",
      G: "40447",
      H: "25",
      I: "Jackson",
      J: "Corneilus",
      K: "Tillery",
      L: "gordonrayt@gmail.com",
      M: "(859) 881-5970",
      N: "Gordon Ray",
      O: "Tillery",
      P: "Gordonrayt@gmail.com",
      Q: "(859) 621-1788",
    },
    "2021/12/03 12:08:33": {
      B: "McKee Lodge, No. 144",
      C: "109 S Broad St",
      D: "",
      E: "London",
      F: "KY",
      G: "40741",
      H: "26",
      I: "Laurel",
      J: "Lyle",
      K: "McClure",
      L: "lylemcclure11@gmail.com",
      M: "(606) 224-9566",
      N: "Nicholas",
      O: "Irvin",
      P: "nicholas.irvin@gmail.com",
      Q: "(606) 309-9727",
    },
    "2021/11/10 17:08:34": {
      B: "Lexington Lodge No. 1",
      C: "3112 Harrodsburg Rd",
      D: "",
      E: "Lexington",
      F: "Kentucky",
      G: "40503",
      H: "20",
      I: "Fayette",
      J: "Jeremy",
      K: "Patches ",
      L: "jeremypatches@gmail.com ",
      M: "(859) 396-5435",
      N: "Don",
      O: "Combs",
      P: "Don.combs@me.com ",
      Q: "(606) 205-2515",
    },
  };

  const handleUploadJson = async () => {
    addLodgesViaCsv(jsonArray);
  };
  return (
    <DashboardLayout>
      <button onClick={handleUploadJson}>Upload json</button>
    </DashboardLayout>
  );
};

export default AddLodge;
