import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import AddContact from "./AddContact";
import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const emails = ["username@gmail.com", "user02@gmail.com"];

export default function AddContactDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (submitting) {
      handleClose();

      props.setSelectedValue(!selectedValue);
      setSubmitting(false);
      return;
    }
  }, [submitting]);
  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        disableTypography
        className="flex justify-between items-center"
      >
        <h3>Add Contact</h3>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <AddContact
        submitting={submitting}
        setSubmitting={setSubmitting}
        lodgeId={props.lodgeId}
      />
    </Dialog>
  );
}

AddContactDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.bool.isRequired,
  lodgeId: PropTypes.string.isRequired,
};
