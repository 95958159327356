import React, { useState } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Admin from "../pages/Admin";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Login from "../pages/Auth/Login";
import Lodge from "../pages/Lodge";
import Help from "../pages/Help";
import Signup from "../pages/Auth/Signup";
import useAuth from "../hooks/useAuth";
import UserContext from "../contexts/UserContext";
import ProspectContext from "../contexts/ProspectContext";
import Reports from "../pages/Reports";
import { v4 as uuidv4 } from "uuid";
import EditForm from "../pages/EditForm";
import Contacts from "../pages/Contacts";
import FormsPage from "../pages/FormsPage";
import EmailTemplates from "../pages/EmailTemplates";
import MassSMSTool from "../pages/MassSMSTool";
import MassSmsGetStarted from "../pages/MassSmsGetStarted";
import Logout from "../pages/Auth/Logout";
import AddContactPage from "../pages/AddContact";
import AdminDashboard from "../pages/Admin/AdminDashboard";
import AddLodge from "../pages/Admin/AddLodge";
import AdminContacts from "../pages/Admin/AdminContact";
import Contact from "../pages/Contact";
import FAQ from "../pages/FAQ";
import FeatureRequest from "../pages/FeatureRequest";

function Routes() {
  const [user, setUser] = useAuth();
  const [prospectInfo, setProspectInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zip: "",
    assignedDistrict: "",
    assignedRegion: "",
    assignedLodge: "",
    status: "prospects",
    leadId: uuidv4(),
    stateId: "1431",
  });
  return (
    <BrowserRouter>
      <Switch>
        <UserContext.Provider value={{ user, setUser }}>
          {<Redirect exact from="/" to={user ? "/home" : "/login"} />}
          <ProspectContext.Provider value={{ prospectInfo, setProspectInfo }}>
            {user && (
              <>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/add-lodge" component={AddLodge} />
                <Route exact path="/admin" component={Admin} />
                <Route
                  exact
                  path="/admin/dashboard"
                  component={AdminDashboard}
                />

                <Route path="/profile/:leadId" component={Profile} />
                <Route path="/lodge/:lodgeId" component={Lodge} />
                <Route path="/help" component={Help} />
                <Route path="/reports" component={Reports} />
                <Route exact path="/:lodgeId/contacts" component={Contacts} />
                <Route path="/:lodgeId/forms" component={FormsPage} />
                <Route
                  path="/:lodgeId/add-contact"
                  component={AddContactPage}
                />
                <Route exact path="/contacts" component={AdminContacts} />
                <Route
                  path="/:lodgeId/:formId/edit-form"
                  component={EditForm}
                />
                <Route path="/:lodgeId/templates" component={EmailTemplates} />
                <Route
                  exact
                  path="/:lodgeId/mass-sms-tool"
                  component={MassSMSTool}
                />
                <Route
                  exact
                  path="/:lodgeId/mass-sms-tool/get-started"
                  component={MassSmsGetStarted}
                />
                <Route path="/logout" component={Logout} />
              </>
            )}
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/home" component={Home} />
            <Route path="/support/contact" component={Contact} />
            <Route path="/support/faq" component={FAQ} />
            <Route path="/support/request" component={FeatureRequest} />
          </ProspectContext.Provider>
        </UserContext.Provider>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
