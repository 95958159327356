import React, { useState, useEffect } from "react";
import ContactsTable from "../components/ContactsTable";
import { CSVLink, CSVDownload } from "react-csv";
import { getLeadsByLodge } from "../utils/utils";
const ContactsListView = ({ lodgeId, selectedValue }) => {
  useEffect(() => {
    handleGetContacts();
  }, []);

  useEffect(() => {
    handleGetContacts();
  }, [selectedValue]);
  const [contacts, setContacts] = useState([]);
  const handleGetContacts = async () => {
    const data = await getLeadsByLodge(lodgeId);
    setContacts(data);
    console.log(data);
  };

  const clickhandler = (name) => {
    console.log(name);
  };

  return (
    <>
      {contacts && (
        <>
          <div className="pt-3">
            <CSVLink
              data={contacts}
              filename={"contact-export.csv"}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <svg
                className="fill-current w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
              </svg>
              <span>Export as CSV</span>
            </CSVLink>
          </div>

          <div className="">
            <ContactsTable data={contacts} clickhandler={clickhandler} />
          </div>
        </>
      )}
    </>
  );
};

export default ContactsListView;
